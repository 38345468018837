import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import FormButtonFM from "./FormButtonFM";
import QuestionContainer from "./QuestionContainer";
import { Request } from "../../../../config/requests";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { isMobile } from "react-device-detect";
import i18n from "../../../../lang/i18n";
import useTranslations from "../../../../hooks/useTranslations";
import PetImage from "../../../common/components/PetImage";
import { existingClaimsDropwodns, existingClaimsDropwodns_DE, mockExistingClaimFlow_subsequent, mockExistingClaimTranslations } from "../../../../config/mockData";
import useExistingFlowCheck from "../../../../hooks/useExistingFlowCheck";
import { useDispatch } from "react-redux";
import { setTranslations, updateDropdowns, updateExistingType, updateFlowQuestions } from "../../../../config/actions";

function DropdownQuestion({
  data,
  setData,
  details,
  showButtons = true,
  onUpdate,
  width,
  single = false,
}) {
  const [answer, setAnswer] = useState(details.answer || "");
  const [dropdown, setDropdown] = useState(null);
  const { getTranslatedText } = useTranslations();
  const { isExistingFlow } = useExistingFlowCheck();
  const dispatch = useDispatch();

  const handleAnswer = (e) => {
    setAnswer(e.target.value);
    dispatch(updateExistingType(e.target.value));

    if (e.target.value === "Subsequent invoice" && isExistingFlow) {
      dispatch(updateFlowQuestions(structuredClone(mockExistingClaimFlow_subsequent)));
      dispatch(setTranslations(mockExistingClaimTranslations));
    }

    details["answer"] = e.target.value;
    if (details.answer) {
      let item = data.manual?.find((i) => i.name === details.title);
      if (item) {
        setData({
          ...data,
          manual: data.manual.map((i) =>
            i.name === details.title
              ? { ...i, value: details.answer, selected: details.selected }
              : i
          ),
        });
      } else {
        item = {
          name: details.title,
          value: details.answer,
          selected: details.selected,
        };
        setData({
          ...data,
          manual: [...data.manual, item],
        });
      }
    }
  };

  const getDropdown = () => {
    if (isExistingFlow) {
      const arrOfOptions = Object.values(existingClaimsDropwodns);

      const dropdownValues = {
        options: arrOfOptions,
        translations: {
          initial_language: "en",
          translations: { en: existingClaimsDropwodns, de: existingClaimsDropwodns_DE },
        },
      };

      setDropdown(dropdownValues);
      return;
    }

    const currentLanguage = i18n.language;
    Request({
      method: "get",
      route: `api/dropdowns/${details.selected}?lang=${currentLanguage}`,
    })
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        setDropdown(json.data);
      });
  };

  useEffect(() => {
    getDropdown();
  }, []);

  return (
    <QuestionContainer width={width}>
      <Box className="InvoiceTableCointainer">
        <Box className="flow-item-wrapper flow-content-spacing">
          <Box className="flow-item-container">
            <div
              className="TitleQuestion"
              style={{ padding: single ? "0 20px" : "" }}
            >
              {getTranslatedText(details.title)}
              {details.description && (
                <div className="TooltipGap">
                  <Tooltip
                    title={getTranslatedText(details.description)}
                    placement="right"
                  >
                    <InfoOutlinedIcon fontSize="small" />
                  </Tooltip>
                </div>
              )}
            </div>
            <div
              className="AnswersContainer"
              style={{ padding: single ? "0 20px" : "" }}
            >
              <div>
                <FormControl
                  fullWidth
                  sx={{
                    padding: "0 !important",
                    height: "56px !important",
                    width: "100%",
                  }}
                >
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={getTranslatedText(answer, dropdown?.translations?.translations)}
                    className="dropdown-select"
                    label={getTranslatedText(dropdown?.name)}
                    onChange={handleAnswer}
                    renderValue={(selected) => (
                      <div
                        style={{
                          maxWidth: isMobile
                            ? "180px"
                            : width === "Full width"
                              ? "40vw"
                              : "17vw",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          textAlign: "start",
                        }}
                      >
                        {selected}
                      </div>
                    )}
                    MenuProps={{
                      PaperProps: {
                        className: "dropdown-select-paper",
                      },
                      classes: {
                        list: "dropdown-select-list",
                      },
                    }}
                  >
                    {dropdown?.options.map((i, ind) => (
                      <MenuItem key={ind} value={i}>
                        {getTranslatedText(
                          i,
                          dropdown?.translations?.translations
                        )}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
            {showButtons && (
              <div
                className="NavBtnsContainer"
                style={{ padding: single ? "0 20px" : "" }}
              >
                <FormButtonFM
                  name="Back"
                  route="back"
                />
                <FormButtonFM
                  name="Next"
                  route="next"
                  onUpdate={onUpdate}
                  data={data}
                  disabled={!answer}
                />
              </div>
            )}
          </Box>
          {single && <PetImage />}
        </Box>
      </Box>
    </QuestionContainer>
  );
}

export default DropdownQuestion;
