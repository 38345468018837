import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ArrowLeft from "../../assets/PetPage/arrow_left_dashed_small.svg";
import ArrowRight from "../../assets/PetPage/arrow_right_dashed.svg";
import PandaMain from "../../assets/UserInfo/panda-logo-main.svg";
import Checked from "../../assets/iconsSmall/check_selected.png";
import CheckedTrue from "../../assets/iconsSmall/check_selected_mobile.png";
import NotChecked from "../../assets/iconsSmall/check_not_selected.png";
import { isMobileOnly } from "react-device-detect";

import ModalPreview from "../../features/claims/modals/ModalPreview";

import SelectedDocument from "../../assets/iconsSmall/document.svg";
import ShowFileIcon from "../../assets/iconsSmall/document-white.svg";
import { Tooltip } from "@mui/material";
import { formatAnyDate, getCurrencyFromInvoice } from "../../config/helpers";

export default function TileEditRowsTable({
  tableData,
  rowSelectHandler,
  isOnlySelectable = false,
  editRows = true,
}) {
  const { t } = useTranslation();
  const [onTitles, setOnTitles] = useState(true);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  useEffect(() => {
    setOnTitles(true);
  }, [editRows]);

  return (
    <div className="SlowShow policy-table-mobile">
      <div className="policy-table-mobile__header">
        <div
          className="policy-table-mobile__back"
          style={{ opacity: onTitles ? 0.5 : 1 }}
        >
          <div
            className="button_red_mobile back margin-0"
            onClick={() => setOnTitles(true)}
          >
            <img src={ArrowLeft} alt="ArrowLeft" />
          </div>
        </div>
        <div className="policy-table-mobile__img">
          <img
            src={PandaMain}
            alt="Panda_Main_Logo"
            className="main-logo-policy__mobile"
            style={{ top: isMobileOnly ? "15px" : "" }}
          />
        </div>
        <div
          className="policy-table-mobile__next"
          style={{ opacity: !onTitles ? 0.5 : 1 }}
        >
          <div
            className="button_red_mobile back margin-0"
            onClick={() => setOnTitles(false)}
          >
            <img src={ArrowRight} alt="ArrowRight" />
          </div>
        </div>
      </div>

      {isModalOpen && (
        <ModalPreview
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          row={selectedRow}
        />
      )}

      {isOnlySelectable
        ? (
          <div className="policy-table-mobile__tiles-wrapper">
            {onTitles ? (
              <table className="tile-edit-table">
                <thead>
                  <tr>
                    {editRows && <th></th>}
                    <th className="tile-edit-table__tile-header">
                      {t("Date")}
                    </th>
                    <th className="tile-edit-table__tile-header">
                      {t("claim")}
                    </th>
                    <th className="tile-edit-table__tile-header">
                      {t("veterinarian")}
                    </th>
                  </tr>
                </thead>
                <tbody className="tile-edit-table__body">
                  {tableData?.map((row, index) => (
                    <tr
                      className={`tile-edit-table__tile-row inverted-mobile-colors ${row.selected ? "selected" : ""
                        } ${editRows ? "" : "result"}`}
                      onClick={() => rowSelectHandler(index)}
                    >
                      {editRows && (
                        <td>
                          {row?.selected ? (
                            <img src={CheckedTrue} alt="Checked" />
                          ) : (
                            <img src={NotChecked} alt="Not-checked" />
                          )}
                        </td>
                      )}
                      <td style={{ textAlign: "center" }}>
                        {formatAnyDate(row?.claimPortalInfo?.invoiceDate)}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {row?.claimNo || t("noInfo")}
                      </td>
                      <td>
                        {" "}
                        {row?.claimPortalInfo?.vendorName || t("noInfo")}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <table className="tile-edit-table">
                <thead>
                  <tr>
                    {editRows && <th></th>}
                    {["Status", "Amount", "Details"].map((header, index) => (
                      <th
                        key={`tile-edit-header-${index}`}
                        className="tile-edit-table__tile-header"
                      >
                        {t(header)}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="tile-edit-table__body">
                  {tableData?.map((row, index) => {
                    const reducedAmount = row?.claimPortalInfo?.amount;
                    const rowAmount = reducedAmount
                      ? `${reducedAmount} ${getCurrencyFromInvoice(row)}`
                      : t("noInfo");

                    return (
                      <tr
                        className={`tile-edit-table__tile-row inverted-mobile-colors ${row.selected ? "selected" : ""
                          } ${editRows ? "" : "result"}`}
                        onClick={() => rowSelectHandler(index)}
                      >
                        {editRows && (
                          <td>
                            {row?.selected ? (
                              <img src={CheckedTrue} alt="Checked" />
                            ) : (
                              <img src={NotChecked} alt="Not-checked" />
                            )}
                          </td>
                        )}

                        <td>{row.claimPortalInfo?.claimStatus.toUpperCase()}</td>
                        <td>{rowAmount}</td>
                        <td>
                          <div
                            style={{ display: "flex", justifyContent: "center" }}
                          >
                            {row?.documents &&
                              row?.documents.length > 0
                              ? (
                                <Tooltip title={t("seeInitDoc")} placement="top">
                                  <div
                                    className={`back_icon_red ${row?.selected ? "back_icon_white" : ""
                                      }`}
                                    style={{ marginRight: "10px" }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setSelectedRow(row);
                                      setIsModalOpen(true);
                                    }}
                                  >
                                    <img
                                      src={
                                        row?.selected
                                          ? ShowFileIcon
                                          : SelectedDocument
                                      }
                                      style={{
                                        color: "#FFFAF0",
                                        top: "5px",
                                        position: "relative",
                                      }}
                                    />
                                  </div>
                                </Tooltip>
                              ) : (
                                <Tooltip title={t("seeInitDoc")} placement="top">
                                  <div
                                    className={`back_icon_red ${row?.selected ? "back_icon_white" : ""
                                      }`}
                                    style={{ marginRight: "10px", opacity: "50%" }}
                                    onClick={(e) => e.stopPropagation()}
                                  >
                                    <img
                                      src={
                                        row?.selected
                                          ? ShowFileIcon
                                          : SelectedDocument
                                      }
                                      style={{
                                        color: "#FFFAF0",
                                        top: "5px",
                                        position: "relative",
                                        opacity: "50%",
                                      }}
                                    />
                                  </div>
                                </Tooltip>
                              )}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        ) : (
          <div className="policy-table-mobile__tiles-wrapper">
            {onTitles ? (
              <table className="tile-edit-table">
                <thead>
                  <tr>
                    {editRows && <th></th>}
                    <th className="tile-edit-table__tile-header">{t("desc")}</th>
                  </tr>
                </thead>
                <tbody className="tile-edit-table__body">
                  {tableData?.map((row, index) => (
                    <tr
                      className={`tile-edit-table__tile-row ${row.selected ? "selected" : ""
                        } ${editRows ? "" : "result"}`}
                      onClick={() => rowSelectHandler(index)}
                    >
                      {editRows && (
                        <td>
                          {row?.selected ? (
                            <img src={Checked} alt="Checked" />
                          ) : (
                            <img src={NotChecked} alt="Not-checked" />
                          )}
                        </td>
                      )}
                      <td style={{
                        textAlign: "start",
                        backgroundColor: editRows
                          ? ""
                          : (row?.changedValues?.Description || row?.IsNewLine)
                            ? "var(--b-color-additional-1)"
                            : "",
                        color: editRows
                          ? ""
                          : (row?.changedValues?.Description || row?.IsNewLine)
                            ? "var(--b-color-bg-main-2)"
                            : "",
                      }}>
                        {row?.changedValues?.Description || row?.Description}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <table className="tile-edit-table">
                <thead>
                  <tr>
                    {editRows && <th></th>}
                    {[
                      // "got", 
                      "quantity2",
                      "Amount"
                    ].map((header, index) => (
                      <th
                        key={`tile-edit-header-${index}`}
                        className="tile-edit-table__tile-header"
                      >
                        {t(header)}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="tile-edit-table__body">
                  {tableData?.map((row, index) => (
                    <tr
                      className={`tile-edit-table__tile-row ${row.selected ? "selected" : ""
                        } ${editRows ? "" : "result"}`}
                      onClick={() => rowSelectHandler(index)}
                    >
                      {editRows && (
                        <td>
                          {row?.selected ? (
                            <img src={Checked} alt="Checked" />
                          ) : (
                            <img src={NotChecked} alt="Not-checked" />
                          )}
                        </td>
                      )}
                      {/* <td>{row?.GOT}</td> */}
                      <td style={{
                        backgroundColor: editRows
                          ? ""
                          : (row?.changedValues?.Quantity || row?.IsNewLine)
                            ? "var(--b-color-additional-1)"
                            : "",
                        color: editRows
                          ? ""
                          : (row?.changedValues?.Quantity || row?.IsNewLine)
                            ? "var(--b-color-bg-main-2)"
                            : "",
                      }}>
                        {row?.changedValues?.Quantity || row?.Quantity}
                      </td>
                      <td style={{
                        backgroundColor: editRows
                          ? ""
                          : (row?.changedValues?.Amount || row?.IsNewLine)
                            ? "var(--b-color-additional-1)"
                            : "",
                        color: editRows
                          ? ""
                          : (row?.changedValues?.Amount || row?.IsNewLine)
                            ? "var(--b-color-bg-main-2)"
                            : "",
                      }}>
                        {row?.changedValues?.Amount || row?.Amount} €
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        )}
    </div>
  );
}
