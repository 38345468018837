import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import SingleQuestion from "./SingleQuestion";
import MultipleQuestions from "./MultipleQuestions";
import ReactPanZoom from "react-image-pan-zoom-rotate";
import { uploadImages } from "../../../config/actions";
import { useTranslation } from "react-i18next";

export default function QuestionSwitcher({
  data,
  setData,
  handleInput,
  onUpdate,
  updateClaim
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const flowQuestions = useSelector((s) => s.flow.flowQuestions);
  const flowStep = useSelector((s) => s.flow.flowCurrentStep);
  const uploadedImages = useSelector((s) => s.flow.uploadedImages);
  const [flowQ, setFlowQ] = useState([]);
  const [selectedImageBlob, setSelectedImageBlob] = useState(null);
  const [selectedImageBase, setSelectedImageBase] = useState(null);
  const [selectedImageUrl, setSelectedImageUrl] = useState(null);
  const [isAttachmentCollapsed, setIsAttachmentCollapsed] = useState(true);
  const indexOfUpload = flowQuestions.findIndex((nestedArray) =>
    nestedArray.some((element) => element.type === "upload")
  );

  function convertImagesToBlob(images) {
    const imageBlobs = [];
    const imagesArray = Array.isArray(images) ? images : [images];
    imagesArray.forEach((image) => {
      const blob = new Blob([image], { type: image.type });
      imageBlobs.push(blob);
    });
    const newImages = imageBlobs.map((image) => {
      return URL.createObjectURL(image);
    });
    return newImages;
  }

  useEffect(() => {
    const composer = async () => {
      if (
        selectedImageBase &&
        selectedImageBase?.type?.split("/")[0] === "image"
      ) {
        let convertedImage = convertImagesToBlob(selectedImageBase);
        setSelectedImageBlob(convertedImage);
      }
      if (selectedImageBase && selectedImageBase?.type === "application/pdf") {
        const url = URL.createObjectURL(selectedImageBase);
        setSelectedImageUrl(url);
      }
    };
    composer();
  }, [selectedImageBase]);

  useEffect(() => {
    if (uploadedImages.length > 0) {
      setSelectedImageBase(uploadedImages[0]);
    }
  }, [uploadedImages]);

  useEffect(() => {
    setFlowQ(flowQuestions[flowStep] || []);
  }, [flowQuestions, flowStep]);

  useEffect(() => {
    return () => {
      dispatch(uploadImages([]));
    };
  }, [dispatch]);

  const toggleAttachmentCollapse = () => {
    setIsAttachmentCollapsed(!isAttachmentCollapsed);
  };

  return (
    <>
      {uploadedImages.length > 0 &&
        flowQuestions.find((el) => el[0]?.type === "upload") &&
        flowStep > indexOfUpload && (
          <div className="attach_btn">
            <div
              className={`attach_btn__container ${!isAttachmentCollapsed ? "opened" : ""} shadow`}
            >
              {!isAttachmentCollapsed && (
                <>
                  <div style={{ overflow: "hidden" }}>
                    {selectedImageBase?.type?.split("/")[0] === "image" && (
                      <ReactPanZoom
                        image={selectedImageBlob}
                        alt="Image alt text"
                      />
                    )}
                    {selectedImageBase?.type === "application/pdf" && (
                      <iframe
                        src={selectedImageUrl}
                        title="image"
                        style={{
                          overflow: "auto",
                          height: "400px",
                          width: "100%",
                        }}
                      />
                    )}
                  </div>
                  {uploadedImages.length > 1 && (
                    <div className="pagination_images">
                      {uploadedImages.map((img, index) => (
                        <div
                          className={`pagination_button ${selectedImageBase === img ? "" : "inactive"}`}
                          onClick={() => setSelectedImageBase(img)}
                        >
                          {index + 1}
                        </div>
                      ))}
                    </div>
                  )}
                </>
              )}
              <div
                className="d-flex justify-content-between pointer mt-1"
                style={{ gap: "5px" }}
                onClick={toggleAttachmentCollapse}
              >
                <span className="black_fat_rooney" style={{ fontSize: "18px" }}>
                  {t("yourAttachments")}{" "}
                  {uploadedImages.length > 0 && `(${uploadedImages.length})`}
                </span>
                <span
                  style={!isAttachmentCollapsed ? {} : { rotate: "180deg" }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="black"
                    className="bi bi-chevron-down"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </div>
        )}
      {flowQ.length === 1 ? (
        <SingleQuestion
          data={data}
          setData={setData}
          handleInput={handleInput}
          flowQuestions={flowQ}
          onUpdate={onUpdate}
          updateClaim={updateClaim}
        />
      ) : (
        <MultipleQuestions
          data={data}
          setData={setData}
          flowQuestions={flowQ}
          onUpdate={onUpdate}
          handleInput={handleInput}
          updateClaim={updateClaim}
        />
      )}
    </>
  );
}
