import { Box } from "@mui/material";
import React, { memo, useEffect } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import FormButtonFM from "../../../components/forms/Forms/FlowManagement/FormButtonFM";
import { FMSwitch } from "../../../components/forms/Forms";
import { isMobile } from "react-device-detect";
import { incorrectDateRangePicked } from "../../../config/helpers";
import moment from "moment";
import { FormComponentsWithImage } from "../../../constants/Forms";
import PetImage from "../../../components/common/components/PetImage";
import useQuestionContainer from "../../../hooks/useQuestionContainer";

const MultipleQuestions = ({
  data,
  setData,
  handleInput,
  flowQuestions,
  onUpdate,
}) => {
  const flowStep = useSelector((s) => s.flow.flowCurrentStep);
  const [searchParams] = useSearchParams();
  const ocrDetails = useSelector((s) => s.flow.uploadValues);
  const getDisableValue = () => {
    return flowQuestions.some((i) => {
      if (i.type === "date_range") {
        return (
          !i.content.from ||
          !i.content.to ||
          incorrectDateRangePicked(
            moment(i?.content?.from, "DD-MM-YYYY"),
            moment(i?.content?.to, "DD-MM-YYYY")
          )
        );
      }
      return !i.content.answer;
    });
  };

  const isWithImage = FormComponentsWithImage[flowQuestions[0]?.type];

  const [questionContainer, buttonContainer] = useQuestionContainer();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    const targetDiv = document.getElementsByClassName("custom_container")[0];
    setTimeout(() => {
      targetDiv.classList.add("SlowShow");
    }, 1);
    return () => {
      targetDiv.classList.remove("SlowShow");
    };
  }, [flowStep]);

  return (
    <div className={isWithImage ? "wave-bg " : "full-width-flow"}>
      <div className="custom_container SlowShow">
        <Box className="multi-question-main">
          <div className="multi-question-container">
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                justifyItems: "center",
                flexWrap: "wrap",
                gap: "10px",
              }}
            >
              <div className="group-title">
                {flowQuestions[0]?.content?.groupTitle}
              </div>
              {flowQuestions.map((i, ind) => (
                <FMSwitch
                  id={ind}
                  data={data}
                  showButtons={false}
                  index={flowStep}
                  setData={setData}
                  key={`question-${i.uniqueId}`}
                  isActive={flowStep + 1}
                  type={i.type}
                  details={i.content}
                  claimId={searchParams.get("claimId")}
                  ocrDetails={ocrDetails[i.content?.uploadId] || {}}
                  handleInput={handleInput}
                  width={flowQuestions[ind]?.content?.width}
                />
              ))}
              <div className="QuestionContainer">
                {flowStep <= flowQuestions.length - 1 &&
                  <div className="NavBtnsContainer">
                    <FormButtonFM
                      name="Back"
                      route="back"
                    />
                    <FormButtonFM
                      name="Next"
                      route="next"
                      onUpdate={onUpdate}
                      data={data}
                      disabled={getDisableValue()}
                    />
                  </div>}
              </div>
            </Box>
            {flowStep <= flowQuestions.length - 1 && <PetImage />}
          </div>
        </Box>
      </div>
    </div>
  );
};

export default memo(MultipleQuestions);
