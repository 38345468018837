import React, { useEffect, useState } from "react";
import { Request } from "../../config/requests";
import { getToken } from "../../config/token";
import { useDispatch, useSelector } from "react-redux";
import {
  updateFlowCurrentStep,
  updateFlowQuestions,
  setFlowLoading,
  setTranslations,
  setFlowType,
  updateIsLastStep,
} from "../../config/actions";
import { initData } from "../../components/common/routes/InitValues";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import QuestionSwitcher from "./components/QuestionSwitcher";
import ErrorModal from "../../components/common/errorModal";
import PandaLoading from "../../components/common/PandaLoading";
import {
  checkLastClaimRecursively,
  isFlowExistingClaim,
  showToast,
} from "../../config/helpers";
import i18n from "../../lang/i18n";
import { useTranslation } from "react-i18next";
import {
  mockExistingClaimFlow,
  mockExistingClaimFlow_subsequent,
  mockExistingClaimTranslations,
} from "../../config/mockData";
import { FLOW_TYPES, LAST_CLAIM_ERROR_MSG } from "../../constants/Flow";
import { checkLastClaim } from "../../services/clams";

export default function NewUpload() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({ ...initData, manual: [] });

  const flowQuestions = useSelector((s) => s.flow.flowQuestions);
  const flowCurrentStep = useSelector((s) => s.flow.flowCurrentStep);
  const currentPetInfo = useSelector((s) => s.flow.selectedPetInfo);
  const flowType = useSelector((s) => s.flow.flowType);
  const selectedFlowType = useSelector((s) => s.flow.selectedFlowTypeToSkip);
  const flowLoading = useSelector((s) => s.flow.flowLoading);


  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [errorData, setErrorData] = useState({
    errorMsg: null,
    timeout: null,
  });

  const { id } = useParams();
  const isExistingFlow = isFlowExistingClaim(searchParams);

  useEffect(() => {
    const isStepToSkip = flowCurrentStep === 0;

    if (
      flowType === FLOW_TYPES["Existing Claim"] &&
      selectedFlowType &&
      isStepToSkip
    ) {
      const skipFirstStep = () => {
        dispatch(updateFlowCurrentStep(1));
      };

      skipFirstStep()
    }
  }, [flowType, selectedFlowType, flowCurrentStep]);

  useEffect(() => {
    if (searchParams.get("type") && id) {
      dispatch(updateFlowQuestions([]));
      dispatch(updateFlowCurrentStep(0));
      dispatch(updateIsLastStep(false));
      dispatch(setFlowType(""));

      const startFlow = async () => {
        window.scrollTo({ top: 0, behavior: "smooth" });

        try {
          setIsLoading(true);

          await checkLastClaimRecursively(id);

          if (isExistingFlow) {
            try {
              getClaimId();
              dispatch(setFlowType(FLOW_TYPES["Existing Claim"]));

              const flowQuestions =
                selectedFlowType
                  ? structuredClone(mockExistingClaimFlow_subsequent)
                  : selectedFlowType === FLOW_TYPES["Cost estimation"]
                    ? structuredClone(mockExistingClaimFlow).slice(0, -1)
                    : structuredClone(mockExistingClaimFlow);

              dispatch(updateFlowQuestions(flowQuestions));
              dispatch(setTranslations(mockExistingClaimTranslations));
            } catch (err) {
              console.log(err);
            } finally {
              setIsLoading(false);
            }

            return;
          }

          Promise.all([getFlow(), getClaimId()]);
        } catch (err) {
          console.error(err);
          const errorStr = err.message;

          if (errorStr === LAST_CLAIM_ERROR_MSG) {
            setError(true);
            setErrorData({
              errorMsg: "maxRequstLimitReached",
              timeout: 5000,
            });
          }
        } finally {
          setIsLoading(false);
        }
      };

      startFlow();
    }
  }, [searchParams.get("type"), id]);

  useEffect(() => {
    const hasClaimsId = searchParams.get("claimId");

    if (hasClaimsId) {
      setError(true);
      setErrorData({
        errorMsg: null,
        timeout: null,
      });
    }
  }, []);

  const getFlow = async () => {
    const currentLanguage = i18n.language;
    return await Request({
      method: "get",
      token: getToken(),
      route: `api/flows/new_claim?type=${encodeURIComponent(
        searchParams.get("type")
      )}&lang=${currentLanguage}`,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error("Request failed with status: " + res.status);
        }
      })
      .then(({ data: response }) => {
        const typeObj = response?.data?.autofill?.find(
          (obj) => obj.code === "type"
        );
        dispatch(setFlowType(typeObj?.value || ""));

        setData({ ...data, id: response.id });
        const content = response.data.content.map((i, ind) => {
          const currData = response.data.autofill[ind];
          i.group = currData.group;
          if (i.type === "upload") {
            i.content.ocr = currData.ocr;
            i.content.uniqueId = currData.uniqueId;
          }
          if (i.type === "dropdown") {
            i.content.selected = currData.selected;
          }
          i.content.isOcr = currData.isOcr;
          i.content.uploadId = currData.uploadId;
          i.content.defaultValue = currData.defaultValue;
          i.uniqueId = currData.uniqueId;
          i.content.groupTitle = currData.groupTitle;
          i.content.width = currData.width;
          i.content.description = currData.description;
          return i;
        });
        const status = content[content.length - 1].content.value;
        localStorage.setItem("flowStatus", status);
        const d =
          content.filter(
            (i) => !["new_claim", "type", "status"].includes(i.type)
          ) || [];

        const groups = d.reduce((acc, i) => {
          return (acc = acc.includes(i.group) ? acc : [...acc, i.group]);
        }, []);
        const values = groups.map((i) => {
          return d.filter((j) => j.group === i);
        });
        dispatch(updateFlowQuestions(values));
        dispatch(setTranslations(response?.data?.translations));

        return values;
      })
      .catch((err) => {
        setError(true);
        setErrorData({
          errorMsg: null,
          timeout: null,
        });
      });
  };

  const getClaimId = async () => {
    return await Request({
      method: "post",
      token: getToken(),
      route: "api/claims",
      values: {
        pet: { ...currentPetInfo, type: currentPetInfo.type },
        policy_id: id,
        cliamType: searchParams.get("type"),
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error("Request failed with status: " + res.status);
        }
      })
      .then((res) => {
        setSearchParams({ claimId: res.id });
        setData({ ...data, cid: res.id });
      })
      .catch(() => {
        setError(true);
        setErrorData({
          errorMsg: null,
          timeout: null,
        });
      });
  };

  const updateClaim = async (extraData, manualData = null) => {
    const manual = flowQuestions.flat().map((i) => ({
      name: i.content.title,
      value: i.content.answer,
      selected: i.content.selected,
    }));

    const payloadValues = extraData?.paymentData?.paymentAccountHolder
      ? {
        id: searchParams.get("claimId"),
        manual,
        type: flowType,
        favorite_payment: {
          accountHolder: extraData?.paymentData?.paymentAccountHolder,
          secondName: extraData?.paymentData?.paymentSecondName,
          iban: extraData?.paymentData?.paymentIban,
        },
        ...manualData,
      }
      : {
        id: searchParams.get("claimId"),
        manual,
        type: flowType,
        ...manualData,
      };

    try {
      const response = await Request({
        method: "post",
        token: getToken(),
        route: `api/claims/update-claim?policy_id=${id}`,
        values: payloadValues,
      });
      return response;
    } catch (e) {
      setError(true);
      setErrorData({
        errorMsg: null,
        timeout: null,
      });
      console.log(e);
    }
  };

  const onUpdate = async (index, extraData = {}) => {
    const response = await updateClaim(extraData);
    dispatch(updateFlowCurrentStep(index));
    return response;
  };

  const handleInput = async (selection, index) => {
    if (flowQuestions.length === index) {
      try {
        const currentLanguage = i18n.language;
        dispatch(setFlowLoading(true));
        await Request({
          method: "post",
          route: `api/claims/update-claim?policy_id=${id}&lang=${currentLanguage}`,
          values: {
            id: searchParams.get("claimId"),
            approve: true,
            type: flowType,
          },
        });
        navigate(`/pet/${id}`);
      } catch (err) {
        console.log(err);
      }
      // dispatch(updateFlowCurrentStep(0));
      dispatch(setFlowLoading(false));
      showToast(t("claimSuccess"));
    } else {
      dispatch(updateFlowCurrentStep(index));
    }
  };

  return (
    <div className="WelcomeContainer no-overflow-x">
      {isLoading || flowLoading ? (
        <div className="m-top-bottom">
          <PandaLoading loadingMsg={"loadingFlowText"} />
        </div>
      ) : (
        <QuestionSwitcher
          data={data}
          setData={setData}
          handleInput={handleInput}
          onUpdate={onUpdate}
          updateClaim={updateClaim}
        />
      )}
      {error && (
        <ErrorModal
          openModal={error}
          setErrorModal={() => setError(!error)}
          errorMsg={errorData?.errorMsg}
          timeout={errorData?.timeout}
        />
      )}
    </div>
  );
}
