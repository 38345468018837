import * as React from "react";
import { useTranslation } from "react-i18next";
import { isMobileOnly } from "react-device-detect";
import ModalWrapper from "../../../components/modalWrapper";

function ModalProcessXML({
  openModalProcessXML,
  setOpenModalProcessXML,
  processXML,
  numberOfClaims,
  totalAmount
}) {
  const { t } = useTranslation();

  function handleClose() {
    setOpenModalProcessXML(false);
  };


  return (
    <ModalWrapper
      headerTitle={t("processPayment")}
      openModal={openModalProcessXML}
      setModalWrapper={() => handleClose()}
    >
      <div
        className="modal_container__body__text"
        style={{
          maxWidth: "540px",
          margin: "0 0 5vh 0",
        }}
      >
        <span>{t("numberOfClaims")}: <span>{numberOfClaims}</span></span>
        <br />
        <span>{t("totalAmount")}: <span>{totalAmount + " €"}</span></span>
      </div>
      <div className="modal_container__body__row-modal">
        <div
          className={`button_red_small ${isMobileOnly && "mobile"}`}
          style={{width: "unset"}}
          onClick={() => {
            processXML();
            handleClose();
          }}
        >
          {t("createXML")}
        </div>
      </div>
    </ModalWrapper>
  );
}

export default ModalProcessXML;