import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { isMobileOnly } from "react-device-detect";
import ModalWrapper from "../../../components/modalWrapper";
import {
  updatePolicyReactivateModalSelect,
} from "../../../config/actions";
import Loading from "../../../components/common/Loading";
import { Markup } from "interweave";
import { TileRow } from "../../../components/table/TileTable";
import CopyToClipboard from "../../../assets/iconsSmall/copy-to-clipboard.svg"

function ModalPolicyReactivate() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isModalOpen = useSelector((s) => s.admin.isPolicyReactivateModalOpen);

  const [policyId, setPolicyId] = useState("");
  const [link, setLink] = useState("");
  const [problemId, setProblemId] = useState(false);
  const [policyDetails, setPolicyDetails] = useState([]);
  const [poliycSent, setPolicySent] = useState(false);
  const [codeLoading, setCodeLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const AZ_FN_DOMAIN = process.env.REACT_APP_AZ_FN_DOMAIN;

  const closeModal = () => {
    dispatch(updatePolicyReactivateModalSelect(false));
    setPolicyId("");
    setPolicyDetails([]);
    setCodeLoading(false);
    setPolicySent(false);
    setProblemId(false);
  };

  const policyConfirm = async () => {
    setCodeLoading(true);
    setProblemId(false);

    fetch(`${AZ_FN_DOMAIN}/api/policyRecovery`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ "policy_id": policyId }),
    })
      .then((response) => {
        if (response.status === 200) {
          setProblemId(false);
          setPolicySent(true);
        } else {
          setProblemId(true);
          setCodeLoading(false);
          setPolicySent(false);
        }
        return response;
      })
      .then((response) => response.json())
      .then((res) => {
        if (res.message) {
          setErrorMessage(res.message)
        } else {
          setPolicyDetails(res.data);
          setLink(res.invoiceLink);
        }
      })
      .finally(() => {
        setCodeLoading(false);
      });
  };

  const handleCopyToClipboard = () => {
    const input = document.querySelector('.modal_container__body__input');
    input.select();
    document.execCommand('copy');
  };

  return (
    <ModalWrapper
      headerTitle={t("policyReactivate")}
      openModal={isModalOpen}
      setModalWrapper={() => closeModal()}
    >
      <div className="modal_container__body__column-modal modal_container__body__column-modal_full-width">
        {!poliycSent && (
          <>
            {codeLoading ? (
              <Loading noText fast />
            ) : (
              <div className="modal_container__body__inputs">
                <div
                  className="modal_container__body__text"
                  style={{
                    maxWidth: "540px",
                  }}
                >
                  {t("policyID")}
                </div>
                <form
                  className="modal_container__body__form"
                  style={{ width: "100%" }}
                  onSubmit={() => policyConfirm()}>
                  <input
                    className="modal_container__body__input"
                    style={{
                      maxWidth: "540px",
                      textAlign: "center",
                      padding: "10px 30px",
                    }}
                    placeholder={t("policyID")}
                    value={policyId}
                    onChange={(e) => setPolicyId(e.target.value)}
                  />
                  <button
                    type="submit"
                    className={`button_red_small ${isMobileOnly && "mobile"}`}
                    disabled={!policyId}
                  >
                    {t("Submit")}
                  </button>
                </form>
              </div>
            )}
          </>
        )}
        {poliycSent && (
          <>
            <div className="modal_container__body__table">
              {policyDetails && <>
                {policyDetails
                  .map((el) => (
                    <TileRow label={el.label} children={el.value}></TileRow>
                  ))}
              </>}
            </div>
            <div className="modal_container__body__inputs">
              <div
                className="modal_container__body__text"
                style={{ maxWidth: "540px" }}
              >
                {t("invoiceLink")}
              </div>
              <div
                className="relative"
                style={{ width: "100%" }}>
                <input
                  className="modal_container__body__input clipboard"
                  placeholder={t("link")}
                  value={link}
                />
                <img
                  className="copy-to-clipboard"
                  src={CopyToClipboard}
                  alt="Copy to clipboard"
                  onClick={() => handleCopyToClipboard()}
                />
              </div>
            </div>
          </>
        )}

        {problemId && (
          <div
            className="login__body__alert"
            style={{ color: "red", marginTop: 0 }}
          >
            <Markup
              content={errorMessage || t("errorPolicyId")}
              className="small text-centered"
            />
          </div>
        )}
      </div>
    </ModalWrapper>
  );
}

export default ModalPolicyReactivate;
