import React, { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { isMobile, isMobileOnly } from "react-device-detect";
import ModalWrapper from "../../../components/modalWrapper";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { attachToExistingClaim, createDuplicateClaim } from "../../../services/clams";
import useAttchFiles from "../../../hooks/useAttachFiles";
import { updateMissedChip, updateMissedDiag } from "../../../config/actions";

function ModalMissingOCR({
  openModalMissingOCR,
  setOpenModalMissingOCR,
  reportType,
  onFinish,
  duplicatesList,
  currentPetInfo,
  updateClaim
}) {
  const { t } = useTranslation();
  const router = useNavigate();
  const dispatch = useDispatch();
  const uploadedImages = useSelector((s) => s.flow.uploadedImages);
  const flowType = useSelector((s) => s.flow.flowType);
  const flowQuestions = useSelector((s) => s.flow.flowQuestions);
  const [hasDuplicate, setHasDuplicate] = useState(true);
  const [reuploadClaim, setReuploadClaim] = useState(false);
  const [showThanks, setShowThanks] = useState(false);
  const [needChip, setNeedChip] = useState(false);
  const [needDiag, setNeedDiag] = useState(false);
  const { id } = useParams();

  const [searchParams] = useSearchParams();
  const { upload } = useAttchFiles({ claimId: searchParams.get("claimId") });

  function handleClose() {
    onFinish();
    setOpenModalMissingOCR(false);
  };

  return (
    <ModalWrapper
      headerTitle={reportType === "duplicates"
        ? t("submitInvoice")
        : (reportType === "diagnosis"
          ? t("missingDiag")
          : t("missingChip"))}
      openModal={openModalMissingOCR}
      setModalWrapper={() => handleClose()}
      noClose
    >
      {reportType === "diagnosis" &&
        <>
          {needDiag
            ? <>
              <div
                className="modal_container__body__text"
                style={{
                  maxWidth: "540px",
                  margin: "0 0 5vh 0",
                }}
              >
                <span>
                  {t(
                    flowType === "Existing Claim" &&
                      flowQuestions[0]?.[0]?.content?.answer === "Diagnosis"
                      ? "needDiag_EC"
                      : "needDiag"
                  )}
                </span>
              </div>
              <div className="modal_container__body__row-modal">
                <div
                  className={`button_red_small ${isMobileOnly && "mobile"}`}
                  onClick={() => router("/")}
                >
                  Ok
                </div>
              </div>
            </>
            : <>
              <div
                className="modal_container__body__text"
                style={{
                  maxWidth: "540px",
                  margin: "0 0 5vh 0",
                }}
              >
                {t("cantFindDiag")}
              </div>
              <div className="modal_container__body__row-modal">
                <div
                  className={`button_red_small ${isMobileOnly && "mobile"}`}
                  onClick={() => {
                    dispatch(updateMissedDiag(true));
                    onFinish();
                  }}
                >
                  {t("yes")}
                </div>
                <div
                  className={`button_red_small ${isMobileOnly && "mobile"}`}
                  onClick={() => setNeedDiag(true)}
                >
                  {t("no")}
                </div>
              </div>
            </>}
        </>}
      {reportType === "chipnumber" &&
        <>
          {needChip
            ? <>
              <div
                className="modal_container__body__text"
                style={{
                  maxWidth: "540px",
                  margin: "0 0 5vh 0",
                }}
              >
                <span>{t("needChip")}</span>
              </div>
              <div className="modal_container__body__row-modal">
                <div
                  className={`button_red_small ${isMobileOnly && "mobile"}`}
                  onClick={() => router("/")}
                >
                  Ok
                </div>
              </div>
            </>
            : <>
              <div
                className="modal_container__body__text"
                style={{
                  maxWidth: "540px",
                  margin: "0 0 5vh 0",
                }}
              >
                <span>{t("cantFindChip")}</span>

                {/* Previous pop-up version with chipnumber */}

                {/* <span>{t("cantFindChip1")}</span>
                {currentPetInfo?.identificationNumber &&
                  <span style={{ color: "#B83739", fontVariantNumeric: "lining-nums proportional-nums" }}>
                    {" " + currentPetInfo?.identificationNumber}
                  </span>}
                <span>{t("cantFindChip2")}</span> */}
              </div>
              <div className="modal_container__body__row-modal">
                <div
                  className={`button_red_small ${isMobileOnly && "mobile"}`}
                  onClick={() => {
                    dispatch(updateMissedChip(true));
                    onFinish();
                  }}
                >
                  {t("yes")}
                </div>
                <div
                  className={`button_red_small ${isMobileOnly && "mobile"}`}
                  onClick={() => setNeedChip(true)}
                >
                  {t("no")}
                </div>
              </div>
            </>}
        </>}
      {reportType === "duplicates" &&
        <>
          {hasDuplicate &&
            <>
              <div
                className="modal_container__body__text"
                style={{
                  maxWidth: "540px",
                  margin: "5vh 0 1vh 0",
                }}
              >
                {t("hasDuplicate1")}
              </div>
              {duplicatesList?.split(",").map((el, ind) => (
                <span key={ind}>{el}</span>
              ))}
              <div
                className="modal_container__body__text"
                style={{
                  maxWidth: "540px",
                  margin: "1vh 0 5vh 0",
                }}
              >
                {t("hasDuplicate2")}
              </div>
              <div className="modal_container__body__row-modal">
                <div
                  className={`button_red_small ${isMobileOnly && "mobile"}`}
                  onClick={() => {
                    attachToExistingClaim(duplicatesList, "Additional attachment", uploadedImages);
                    router("/flow-completed");
                    setOpenModalMissingOCR(false);
                  }}
                >
                  {t("yes")}
                </div>
                <div
                  className={`button_red_small ${isMobileOnly && "mobile"}`}
                  onClick={() => {
                    setHasDuplicate(false);
                    setReuploadClaim(true);
                  }}
                >
                  {t("no")}
                </div>
              </div>
            </>}
          {reuploadClaim &&
            <>
              <div
                className="modal_container__body__text"
                style={{
                  maxWidth: "540px",
                  margin: "0 0 5vh 0",
                }}
              >
                {t("sureReuploadClaim")}
              </div>
              <div className="modal_container__body__row-modal">
                <div
                  className={`button_red_small ${isMobileOnly && "mobile"}`}
                  onClick={async () => {
                    await upload({ files: uploadedImages || [] });
                    createDuplicateClaim(duplicatesList, id);
                    router("/flow-completed");
                    setOpenModalMissingOCR(false);
                  }}
                >
                  {t("yes")}
                </div>
                <div
                  className={`button_red_small ${isMobileOnly && "mobile"}`}
                  onClick={() => {
                    setReuploadClaim(false);
                    setShowThanks(true);
                  }}
                >
                  {t("no")}
                </div>
              </div>
            </>}
          {showThanks &&
            <>
              <div
                className="modal_container__body__text"
                style={{
                  maxWidth: "540px",
                  margin: "0 0 5vh 0",
                }}
              >
                {t("thankModal")}
              </div>
              <div
                className={`button_red_small ${isMobileOnly && "mobile"}`}
                style={{ minWidth: "225px" }}
                onClick={() => router("/claims")}
              >
                {t("myDamages")}
              </div>
              <div
                className={`button_red_small ${isMobileOnly && "mobile"}`}
                style={{ minWidth: "225px", margin: "15px 0 0 0" }}
                onClick={() => router("/")}
              >
                {t("customerPortal")}
              </div>
            </>}
        </>}
    </ModalWrapper>
  );
}

export default ModalMissingOCR;