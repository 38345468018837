import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Request } from "../../config/requests";
import { getToken } from "../../config/token";
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { isMobileOnly } from 'react-device-detect';
import Loading from '../../components/common/Loading';
import { showToast } from '../../config/helpers';
import { updateUser } from '../../config/actions';

export default function Payment({ quoteId, insuredItemId, setOpenModalStripe, subscriptionId, customerId, clientSecret }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const token = getToken();
  const stripe = useStripe();
  const elements = useElements();
  const userInfo = useSelector((state) => state.form.user);
  const [errorMessage, setErrorMessage] = useState(null);
  const [updateLoading, setUpdateLoading] = useState(false);

  const handleUpdate = async () => {
    const response = await Request({
      method: "get",
      token: token,
      route: `api/payment/payment_details?quoteId=${quoteId}&insuredItemId=${insuredItemId}`,
    });

    if (response.ok) {
      const data = await response.json();
      dispatch(updateUser({ ...userInfo, card4: data.last4, type: data.type, brand: data.brand, additionalInfo: data.additionalInfo }));
    } else {
      const data = await response.json();
      console.error('Error handleUpdate:', data);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setUpdateLoading(true);

    if (!stripe || !elements) {
      return;
    }

    try {
      const result = await elements.submit();

      if (result.error) {
        setErrorMessage(result.error.message);
        console.error('Error during elements submit:', result.error.message);
        return;
      }

      const { setupIntent, error } = await stripe.confirmSetup({
        elements,
        clientSecret,
        confirmParams: {
          return_url: 'https://your-success-url.com',
        },
        redirect: 'if_required',
      });

      if (error) {
        setErrorMessage(error.message);
        console.error('Error confirming setup intent:', error.message);
        return;
      }

      console.log('SetupIntent successfully confirmed:', setupIntent);

      const response = await Request({
        method: "post",
        token: token,
        route: "api/payment/update_payment_method",
        values: {
          paymentMethodId: setupIntent.payment_method,
          customerId: customerId,
          subscriptionId: subscriptionId,
        },
      });

      if (response.ok) {
        console.log('Payment method updated successfully');
        handleUpdate();
        setOpenModalStripe(false);
        showToast(t("paymentMethodSuccessfullyUpdated"));
      } else {
        const data = await response.json();
        console.error('Error updating payment method:', data);
        showToast(t("somethingWentWrong"), { error: true });
        setErrorMessage('Failed to update payment method. Please try again.');
      }
    } catch (error) {
      console.error('Error during setup confirmation:', error);
      showToast(t("somethingWentWrong"), { error: true });
      setErrorMessage('Failed to confirm payment setup. Please try again.');
    } finally {
      setUpdateLoading(false);
    }
  };


  return (
    <div className="stripe-container">
      {clientSecret ? (
        <form onSubmit={handleSubmit} className="stripe-body">
          <PaymentElement />
          {errorMessage && <div>{errorMessage}</div>}
          {updateLoading
            ? <Loading noText fast height={''} scroll={false} />
            : <button
              className={`button_red_small ${isMobileOnly && "mobile"}`}
              style={{ width: 'unset' }}
              type="submit" disabled={!stripe || !elements}
            >
              {t('savePaymentMethod')}
            </button>}
        </form>
      ) : (
        <Loading noText fast height={'50vh'} scroll={false} />
      )}
    </div>
  );
}
