import React, { useEffect, useState } from "react";
import QuestionContainer from "./QuestionContainer";
import FormButtonFM from "./FormButtonFM";
import { handleParagraphOcr } from "../../../../features/ocr/helper-functions";
import InvoiceUpload from "../../../../features/ocr/invoiceUpload/InvoiceUpload";
import Loading from "../../../common/Loading";
import { useDispatch, useSelector } from "react-redux";
import {
  updateDocCounter,
  updateFlowCurrentStep,
  updateUploadValues,
  uploadImages,
} from "../../../../config/actions";
import { Box, Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useNavigate, useSearchParams } from "react-router-dom";
import heic2any from "heic2any";
import useTranslations from "../../../../hooks/useTranslations";
import useAttchFiles from "../../../../hooks/useAttachFiles";
import ModalMissingOCR from "../../../../features/claims/modals/ModalMissingOCR";
import { MISSING_OCR_TYPES } from "../../../../constants/Flow";
import { useTranslation } from "react-i18next";
import useQuestionContainer from "../../../../hooks/useQuestionContainer";
import useExistingFlowCheck from "../../../../hooks/useExistingFlowCheck";
import { existingClaimsDropwodns } from "../../../../config/mockData";
import { FMConstants } from "../../../../constants/Forms";
import useCompleteFlow from "../../../../hooks/useCompleteFlow";
import Exclamation from "../../../../assets/iconsSmall/exclamation_mark_white.svg";
import { isMobileOnly } from "react-device-detect";
import { projectSettings } from "../../../../config/setting";
import { showToast } from "../../../../config/helpers";

const defaultOcr = {
  picture: [],
  inProgress: false,
  step: 0,
  manual: [],
  ocr: {},
  tables: [],
  details: {},
};

export default function Upload({
  data,
  setData,
  details,
  showButtons = true,
  onUpdate,
  width,
  updateClaim,
}) {
  const { t } = useTranslation();
  const router = useNavigate();
  const [currentMissingCounter, setCurrentMissingCounter] = useState(0);
  const [forRenderCounter, setForRenderCounter] = useState(0);
  const [missingTypes, setMissingTypes] = useState([]);
  const [duplicatesList, setDuplicatesList] = useState("");
  const [openModalMissingOCR, setOpenModalMissingOCR] = useState(false);
  const [groupsList, setGroupsList] = useState([]);
  const [claimIdTemp, setClaimIdTemp] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const flowStep = useSelector((s) => s.flow.flowCurrentStep);
  const currentPetInfo = useSelector((s) => s.flow.selectedPetInfo);
  const flowQuestions = useSelector((s) => s.flow.flowQuestions);
  const flowType = useSelector((s) => s.flow.flowType);

  const dispatch = useDispatch();
  const [ocrDetails, setOcrDetails] = useState(defaultOcr);
  const [searchParams] = useSearchParams();
  const [disableNext, setDisableNext] = useState(true);
  const { getTranslatedText } = useTranslations();
  // const { upload } = useAttchFiles({ claimId: searchParams.get("claimId") });

  const [picGroup, setPicGroup] = useState([]);
  const [history, setHistory] = useState([]);
  const [docCounter, setDocCounter] = useState(0);

  const [questionContainer, buttonContainer] = useQuestionContainer();
  const { isExistingFlow } = useExistingFlowCheck()

  const { compleFlow } = useCompleteFlow();

  async function onDrop(pictures) {
    let isFileList = false;
    let picture = pictures;
    if (pictures instanceof FileList) {
      isFileList = true;
      picture = Array.from(pictures);
    }

    const newHistory = picture.map((pic, index1) => {
      const foundPic = history.find((prevPic, index2) => {
        return (
          (prevPic.file.name === pic.name &&
            index1 === index2 &&
            prevPic.isDeleted === true) ||
          (prevPic.file.name === pic.name &&
            index1 !== index2 &&
            prevPic.isDeleted === false)
        );
      });
      if (foundPic) {
        return { file: pic, isDeleted: true };
        // return { file: pic, isDeleted: isFileList ? false : true };
      }
      return { file: pic, isDeleted: false };
    });

    if (isFileList) {
      setHistory((prev) => [...prev, ...newHistory]);
    } else {
      setHistory(newHistory);
    }

    const notDeletedPictures = newHistory
      .filter((obj) => obj.isDeleted === false)
      .map((obj) => obj.file);

    const files = await Promise.all(
      notDeletedPictures.map(async (file) => {
        if (file.name.toLowerCase().includes(".heic")) {
          const blob = await fetch(URL.createObjectURL(file)).then((r) =>
            r.blob()
          );
          try {
            const convertedBuffer = await heic2any({
              blob,
              toType: "image/jpeg",
            });
            const convertedFile = new File(
              [convertedBuffer],
              `${file.name.replace(/\.heic$/i, ".jpg")}`,
              {
                type: "image/jpeg",
              }
            );
            return convertedFile;
          } catch (error) {
            console.error("Error converting HEIC to JPEG:", error);
            if (file.name.toLowerCase().includes(".heic")) {
              const updatedTypeFile = new File([file], file.name, {
                type: "image/heic",
              });
              return updatedTypeFile; // Return the original file in case of conversion error
            } else {
              return file;
            }
          }
        } else {
          return file;
        }
      })
    );

    setGroupsList([{ opened: true, picGroup: files }]);
    setOcrDetails({ ...ocrDetails, picture: files });

    setPicGroup(files);
    dispatch(uploadImages(files));

    setTimeout(() => {
      setDisableNext(false);
    }, 100);
  }

  const onSetOcrDetails = (ocr) => {
    if (!ocr?.loading) {
      if (
        (ocr?.details?.Diagnosis || !projectSettings.diagCheck) &&
        (ocr?.details?.ChipNumber || !projectSettings.chipCheck) &&
        (ocr?.details?.DuplicateClaims?.content == "" || !projectSettings.duplicatesCheck)
      ) {
        dispatch(updateFlowCurrentStep(flowStep + 1));
      } else {
        if (!ocr?.details?.Diagnosis && projectSettings.diagCheck) {
          setForRenderCounter((prev) => prev + 1);
          setMissingTypes((prev) => [...prev, MISSING_OCR_TYPES.diagnosis]);
        }
        if (!ocr?.details?.ChipNumber && projectSettings.chipCheck) {
          setForRenderCounter((prev) => prev + 1);
          setMissingTypes((prev) => [...prev, MISSING_OCR_TYPES.chipnumber]);
        }
        if (
          ocr?.details?.DuplicateClaims?.content &&
          ocr?.details?.DuplicateClaims?.content !== "" &&
          projectSettings.duplicatesCheck && !isExistingFlow
        ) {
          setDuplicatesList(ocr?.details?.DuplicateClaims?.content);
          setForRenderCounter((prev) => prev + 1);
          setMissingTypes((prev) => [...prev, MISSING_OCR_TYPES.duplicates]);
        }
      }
    }

    setOcrDetails(ocr);
    dispatch(updateUploadValues({ [details.uniqueId]: ocr || defaultOcr }));
  };

  const onFinish = () => {
    setCurrentMissingCounter((prev) => prev + 1);
  };

  const errorHandler = (e) => {
    showToast(t(e.message || "somethingWentWrong"), { error: true });
    router("/");
  }

  useEffect(() => {
    if (forRenderCounter > 0) {
      setOpenModalMissingOCR(true);
    }
  }, [forRenderCounter]);

  useEffect(() => {
    dispatch(updateDocCounter(docCounter));
  }, [docCounter]);

  useEffect(() => {
    if (currentMissingCounter === forRenderCounter) {
      setOpenModalMissingOCR(false);
      if (currentMissingCounter > 0 || (currentMissingCounter === 0 && Object.keys(ocrDetails.details).length > 0)) {
        dispatch(updateFlowCurrentStep(flowStep + 1));

        const isLastStep = flowStep === flowQuestions.length - 1;
        const isLastStepUploadOcr =
          flowQuestions[flowStep][0]?.type === FMConstants.UPLOAD &&
          flowQuestions[flowStep][0]?.content?.ocr;
        if (isLastStepUploadOcr && isLastStep) {
          compleFlow();
          router("/flow-completed");
        }
      }
    }
  }, [currentMissingCounter, forRenderCounter, ocrDetails]);

  const handleInvoice = async () => {
    let isOcrEnabledToAnalize = !details.ocr;

    try {
      setIsLoading(true);
      let result = await handleParagraphOcr(
        ocrDetails?.picture,
        ocrDetails,
        data,
        setClaimIdTemp,
        onSetOcrDetails,
        searchParams.get("claimId"),
        isOcrEnabledToAnalize,
        currentPetInfo,
        setDocCounter,
        errorHandler
      );
      if (isOcrEnabledToAnalize) {
        // Previous logic below

        // await upload({
        //   files: ocrDetails?.picture || [],
        // });

        let manualData = { "picturesId": [...result.attachedFilesId] };
        updateClaim({}, manualData);
        dispatch(updateFlowCurrentStep(flowStep + 1));
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <QuestionContainer width={width}>
      {openModalMissingOCR && (
        <ModalMissingOCR
          openModalMissingOCR={openModalMissingOCR}
          setOpenModalMissingOCR={setOpenModalMissingOCR}
          onFinish={onFinish}
          reportType={missingTypes[currentMissingCounter]}
          duplicatesList={duplicatesList}
          currentPetInfo={currentPetInfo}
          updateClaim={updateClaim}
        />
      )}
      {isLoading && <Loading noText />}
      {showButtons && !isLoading && (
        <Box style={{ width: "100%" }}>
          <Box className="flow-item-wrapper_less-margin">
            <Box className="flow-item-column">
              <div className="TitleQuestion flow-title-text flow-title-center">
                {getTranslatedText(details.title)}
                {details.description && (
                  <div className="TooltipGap">
                    <Tooltip
                      title={getTranslatedText(details.description)}
                      placement="right"
                    >
                      <InfoOutlinedIcon fontSize="small" />
                    </Tooltip>
                  </div>
                )}
              </div>
              <div className="AnswersContainer AnswersContainerUpload">
                <InvoiceUpload
                  handleParagraphOcr={handleInvoice}
                  onDrop={onDrop}
                  ocrDetails={ocrDetails}
                  setOcrDetails={setOcrDetails}
                  groupsList={groupsList}
                  setGroupsList={setGroupsList}
                  setDisableNext={setDisableNext}
                  setPicGroup={setPicGroup}
                  picGroup={picGroup}
                  history={history}
                  setHistory={setHistory}
                />
              </div>
              {flowType !== "Liability claim" && (
                <div
                  className={`invoice-table-info ${isMobileOnly ? "mobile" : ""
                    }`}
                  style={{ margin: "40px 0 10px 0" }}
                >
                  <div>
                    <img src={Exclamation} alt="Info-image" />
                  </div>
                  {flowType === "Cost estimation"
                    ? t("achtungChip_CE")
                    : t("achtungChip")}
                </div>
              )}
              <div
                className="NavBtnsContainer"
                style={{ marginBottom: "20px" }}
              >
                <FormButtonFM
                  name="Back"
                  route="back"
                />
                <FormButtonFM
                  name="Next"
                  route="next"
                  onUpdate={handleInvoice}
                  disabled={disableNext}
                />
              </div>
            </Box>
          </Box>
        </Box>
      )}
    </QuestionContainer>
  );
}
