import React, { useEffect, useRef, useState } from "react";
import Loading from "../../../components/common/Loading";
import { useTranslation } from "react-i18next";
import { Request } from "../../../config/requests";
import { getToken } from "../../../config/token";
import { Input } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import InputAdornment from "@mui/material/InputAdornment";
import ReactPanZoom from 'react-image-pan-zoom-rotate';
import heic2any from "heic2any";
import { recordImage } from "../../../services/images";
import Header from "../../../components/common/Header";
import Footer from "../../../components/common/Footer";
import { isMobile, isMobileOnly } from "react-device-detect";
import PawUI from "../../../components/ui/PawUI";
import Exclamation from "../../../assets/iconsSmall/exclamation_mark_dark.svg";


function ReadClaim() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [tableDetails, setTableDetails] = React.useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedImageBlob, setSelectedImageBlob] = useState(null);
  const [selectedImageUrl, setSelectedImageUrl] = useState(null);
  const [totalAmountTaxed, setTotalAmountTaxed] = useState(0);
  const [dataPrepared, setDataPrepared] = useState(false);
  const [showNotification, setShowNotification] = useState(false);

  const classes = {
    gotInput: {
      cursor: "pointer",
      border: "1px solid var(--b-color-bg-additional-2)",
      padding: "0px 14px",
      borderRadius: "4px",
      width: "100px",
    },
  };

  const fileInputRef = useRef(null);

  const handleAttachClick = () => {
    fileInputRef.current.click();
  };

  const getTotalAmount = () => {
    let totalSum = tableDetails.reduce((acc, el) => {
      const amount = el?.properties?.Amount?.value?.amount || 0;
      return acc += amount;
    }, 0);
    return Math.round(totalSum * 100) / 100;
  };

  const getTotalAmountTaxed = () => {
    let totalSum = tableDetails.reduce((acc, el) => {
      const amount = (el.properties.Amount.value.amount * (1 + parseFloat(el.properties.TaxRate.value) / 100)) || 0;
      return acc += amount;
    }, 0);
    return Math.round(totalSum * 100) / 100;
  };

  const handleFileChange = async (event) => {
    let pictures = event.target.files;
    if (pictures instanceof FileList) {
      pictures = Array.from(pictures);
    }
    const files = await Promise.all(
      pictures.map(async (file) => {
        if (file.name.toLowerCase().includes(".heic")) {
          const blob = await fetch(URL.createObjectURL(file)).then((r) =>
            r.blob()
          );
          try {
            const convertedBuffer = await heic2any({
              blob,
              toType: "image/jpeg",
            });
            const convertedFile = new File(
              [convertedBuffer],
              `${file.name.replace(/\.heic$/i, ".jpg")}`,
              {
                type: "image/jpeg",
              }
            );
            return convertedFile;
          } catch (error) {
            console.error("Error converting HEIC to JPEG:", error);
            if (file.name.toLowerCase().includes(".heic")) {
              const updatedTypeFile = new File([file], file.name, {
                type: "image/heic",
              });
              return updatedTypeFile;
            } else {
              return file;
            }
          }
        } else {
          return file;
        }
      })
    );
    setSelectedFiles(files[0]);
  };

  const handleChange = (index, key, e) => {
    const value = e.target.value;
    if (value >= 0) {
      handleUpdateLine(index, key, e);
    }
  };

  function handleUpdateLine(index, key, event) {
    let val = Number(event.target.value);
    let newTableDetails = [...tableDetails];
    if (key === "tax") newTableDetails[index].properties.TaxRate.value = val;
    if (key === "amount") newTableDetails[index].properties.Amount.value.amount = val;
    setTableDetails(newTableDetails);
    setTotalAmountTaxed(getTotalAmountTaxed());
  }

  const analyzeClaim = async () => {
    setShowNotification(false);
    setIsLoading(true);
    const attachedFilesId = await recordImage([selectedFiles])
      .then((res) => {
        const data = res.json()
        return data;
      })
      .then((res) => {
        return res.data;
      })
      .catch(console.error)

    let promiseClaimRecord = new Promise((resolve, reject) => {
      try {
        Request({
          method: "post",
          route: "api/claims/read-claim",
          token: getToken(),
          values: {
            picturesId: attachedFilesId,
          },
        })
          .then((res) => {
            if (res.status === 500) {
              setIsLoading(false);
              setShowNotification(true);
              reject(new Error("Internal Server Error"));
            } else {
              return res.json();
            }
          })
          .then((json) => {
            resolve(json);
          })
          .catch((error) => {
            console.error("Error read-claim: ", error);
            setIsLoading(false);
            setShowNotification(true);
          });
      } catch (e) {
        console.error("Error read-claim: ", e);
        setIsLoading(false);
        setShowNotification(true);
      }
    });
    let claimRecord = await promiseClaimRecord;
    setTableDetails(claimRecord)
    setIsLoading(false);
  };

  function convertImagesToBlob(images) {
    const imageBlobs = [];
    const imagesArray = Array.isArray(images) ? images : [images];
    imagesArray.forEach((image) => {
      const blob = new Blob([image], { type: image.type });
      imageBlobs.push(blob);
    });
    const newImages = imageBlobs.map((image) => {
      return URL.createObjectURL(image);
    });
    return newImages;
  };

  useEffect(() => {
    if (!selectedFiles) {
      setSelectedImageBlob(null);
      setSelectedImageUrl(null);
    } else {
      const composer = async () => {
        if (
          selectedFiles &&
          selectedFiles?.type?.split("/")[0] === "image"
        ) {
          let convertedImage = convertImagesToBlob(selectedFiles);
          setSelectedImageBlob(convertedImage);
        }
        if (selectedFiles && selectedFiles?.type === "application/pdf") {
          const url = URL.createObjectURL(selectedFiles);
          setSelectedImageUrl(url);
        }
      };
      composer();
    }
  }, [selectedFiles]);

  useEffect(() => {
    setTableDetails([]);
    setTotalAmountTaxed(0);
    setDataPrepared(false);
    setShowNotification(false);
  }, [selectedImageUrl, selectedImageBlob]);

  useEffect(() => {
    if (tableDetails.length > 0 && !dataPrepared) {
      let newTableDetails = [...tableDetails];
      newTableDetails.forEach((el) => {
        if (el.properties && !el.properties.hasOwnProperty("TaxRate")) {
          el.properties.TaxRate = {
            value: 19,
          };
        }
      });
      setTableDetails(newTableDetails);
      setTotalAmountTaxed(getTotalAmountTaxed());
      setDataPrepared(true);
    }
  }, [tableDetails]);

  return (
    <div className="main-container SlowShow">
      <Header />
      <div className="page-content relative claims-content">

        {/* Background paws */}

        {isMobile ? (
          <>
            <PawUI
              top="1vh"
              right="6vw"
              rotation="right"
              width="25vw"
              zIndex="0"
            />
            <PawUI
              top="53vh"
              left="-20vw"
              rotation="right"
              width="60vw"
              zIndex="0"
            />
            <PawUI
              bottom="-23vh"
              right="-27vw"
              rotation="left"
              width="70vw"
              zIndex="0"
            />
          </>
        ) : (
          <>
            <PawUI
              top="20vh"
              right="1vw"
              rotation="right"
              width="350px"
              zIndex="0"
            />
            <PawUI
              bottom="-10vh"
              left="20vw"
              rotation="left"
              width="150px"
              zIndex="0"
            />
            <PawUI
              top="10vh"
              left="20vw"
              rotation="left"
              width="250px"
              zIndex="0"
            />
          </>
        )}
        <div className="FMContainer">
          <div className="AuditContent">
            <div className="DuplicateWrap">
              <div className="DuplicateDocWrap">
                {selectedFiles &&
                  (selectedImageBlob || selectedImageUrl) &&
                  <div className="PdfDoc">
                    {selectedFiles?.type?.split("/")[0] === "image" && (
                      <ReactPanZoom
                        image={selectedImageBlob}
                        alt="Image alt text"
                      />
                    )}
                    {selectedFiles?.type === "application/pdf" && (
                      <iframe
                        src={selectedImageUrl}
                        title="image"
                        style={{
                          overflow: "auto",
                          height: "600px",
                          width: "100%",
                        }}
                      />
                    )}
                  </div>}
                <div className="checkDoc" >
                  <div className="ActionBtnBlock">
                    <div
                      className="ButtonReverse Enabled"
                      style={{
                        margin: "5px 10px",
                        height: "46px",
                        width: "200px",
                        display: "flex",
                        alignItems: "center",
                        alignSelf: "flex-start",
                        opacity: isLoading ? "0.5" : "1",
                        pointerEvents: isLoading ? "none" : "",
                      }}
                      onClick={handleAttachClick}
                    >
                      {t("uploadDocument")}
                    </div>
                    <input
                      ref={fileInputRef}
                      // accept=".jpg,.png"
                      onChange={handleFileChange}
                      type="file"
                      hidden
                    />
                    <div
                      className="ButtonReverse Enabled"
                      style={{
                        margin: "5px 10px",
                        height: "46px",
                        width: "200px",
                        display: "flex",
                        alignItems: "center",
                        alignSelf: "flex-start",
                        opacity: (!(selectedFiles instanceof File) || tableDetails.length || isLoading) ? "0.5" : "1",
                        pointerEvents: (!(selectedFiles instanceof File) || tableDetails.length || isLoading) ? "none" : "",
                      }}
                      onClick={analyzeClaim}
                    >
                      {t("analyzeClaim")}
                    </div>
                  </div>
                  {isLoading && <Loading noText fast height={"50vh"} scroll={false} />}
                  {showNotification &&
                    <div className="modal_container__body__info" style={{ width: isMobileOnly ? "unset" : "800px" }}>
                      <span>
                        {t("readClaimFail_start")}
                        <span style={{ color: "#52bb37" }}>web_panda_admin@mazars.com.ge</span>
                        {t("readClaimFail_end")}
                      </span>
                      <img src={Exclamation} />
                    </div>}
                  {(tableDetails.length &&
                    (selectedFiles instanceof File))
                    ? <div>
                      <TableContainer>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          <TableHead sx={{ backgroundColor: "#fff" }}>
                            <TableRow>
                              {[
                                "date",
                                "desc",
                                "got",
                                "count",
                                "amount2",
                                "tax",
                                "totalAmount2",
                              ].map((h, ind) => (
                                <TableCell key={`head-tc-${ind}`} align="left">
                                  {t(h)}
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody sx={{ backgroundColor: "#ffffff86" }}>
                            {tableDetails?.map((row, index) => (
                              <TableRow
                                key={row.Id}
                                sx={{
                                  verticalAlign: "top",
                                  "&:last-child td, &:last-child th": { border: 0 },
                                }}
                              >
                                <TableCell className="CellsSlim" key={1}>
                                  <div>{row?.properties?.Date?.content}</div>
                                </TableCell>
                                <TableCell className="CellsSlim" key={2}>
                                  <div>{row?.properties?.Description?.value}</div>
                                </TableCell>
                                <TableCell className="CellsSlim" key={3}>
                                  <div>{row?.properties?.GOT}</div>
                                </TableCell>
                                <TableCell className="CellsSlim" key={4}>
                                  <div>{row?.properties?.Quantity?.value}</div>
                                </TableCell>
                                <TableCell className="CellsSlim" key={5}>
                                  <Input
                                    value={parseFloat(row?.properties?.Amount?.value?.amount)}
                                    disableUnderline
                                    sx={{
                                      ...classes.gotInput,
                                      width: "110px",
                                    }}
                                    type="number"
                                    align="right"
                                    inputProps={{ min: 0 }}
                                    endAdornment={
                                      (<InputAdornment position="end">€</InputAdornment>)
                                    }
                                    onChange={(e) => handleChange(index, "amount", e)}
                                  />
                                </TableCell>
                                <TableCell className="CellsSlim" key={6}>
                                  <Input
                                    value={parseFloat(row?.properties?.TaxRate?.value)}
                                    disableUnderline
                                    sx={{
                                      ...classes.gotInput,
                                    }}
                                    type="number"
                                    align="right"
                                    inputProps={{ min: 0 }}
                                    endAdornment={
                                      (<InputAdornment position="end">%</InputAdornment>)
                                    }
                                    onChange={(e) => handleChange(index, "tax", e)}
                                  />
                                </TableCell>
                                <TableCell className="CellsSlim" key={7}>
                                  <div style={{ minWidth: "max-content" }}>
                                    {(row?.properties?.Amount?.value?.amount * (1 + parseFloat(row?.properties?.TaxRate?.value) / 100)).toFixed(2) + " €"}
                                  </div>
                                </TableCell>
                              </TableRow>
                            ))}
                            <TableRow>
                              <TableCell className="CellsSlim" colSpan={4}>
                                <div className="inputTitle"> {t("totalInfo")} </div>
                              </TableCell>
                              <TableCell className="CellsSlim">
                                <strong>{getTotalAmount() + " €"}</strong>
                              </TableCell>
                              <TableCell className="CellsSlim"></TableCell>
                              <TableCell className="CellsSlim">
                                <strong>{totalAmountTaxed + " €"}</strong>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                    : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div >
  );
}

export default ReadClaim;
