import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateFlowCurrentStep,
  updateFlowQuestions,
} from "../../../../config/actions";
import FormButtonFM from "./FormButtonFM";
import QuestionContainer from "./QuestionContainer";
import { Box, Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import useTranslations from "../../../../hooks/useTranslations";
import Welcome from "../../../common/components/Welcome";
import PetImage from "../../../common/components/PetImage";
import useQuestionContainer from "../../../../hooks/useQuestionContainer";
import { isMobile } from "react-device-detect";

function MCQ({ data, setData, details, showButtons = true, width, onUpdate }) {
  const dispatch = useDispatch();
  const flowQuestions = useSelector((s) => s.flow.flowQuestions);
  const flowStep = useSelector((s) => s.flow.flowCurrentStep);
  const [clickedItem, setClickedItem] = useState(null);
  const { getTranslatedText } = useTranslations();
  const [questionContainer, buttonContainer] = useQuestionContainer();

  const onSetActive = (idx) => {
    details.answer = details[idx]?.choice;
    details.title = details[idx]?.title;
    setClickedItem(idx);
    const dataMcq = details[idx]?.data[0]?.content || [];
    const autofill = details[idx]?.data[0]?.autofill || [];
    const mixed = dataMcq
      .map((i, ind) => {
        const currData = autofill[ind];
        i.group = currData.group;
        if (i.type === "upload") {
          i.content.ocr = currData.ocr;
          i.content.uniqueId = currData.uniqueId;
        }
        i.content.isOcr = currData.isOcr;
        i.content.uploadId = currData.uploadId;
        i.uniqueId = currData.uniqueId;
        i.content.defaultValue = currData.defaultValue;
        return [i];
      })
      .filter((i) => i[0].type !== "status");
    let item = data?.manual?.find((i) => i.name === details[idx].title);
    if (item) {
      setData({
        ...data,
        manual: data?.manual.map((i) =>
          i.name === details[idx].title
            ? { ...i, value: details[idx].choice }
            : i
        ),
      });
    } else {
      item = {
        name: details[idx].title,
        value: details[idx].choice,
      };
      setData({
        ...data,
        manual: [...data.manual, item],
      });
    }
    const qs = flowQuestions.slice(0, flowStep + 1);
    dispatch(updateFlowQuestions([...qs, ...mixed]));
    onNext();
  };

  const onNext = () => {
    dispatch(updateFlowCurrentStep(flowStep + 1));
  };

  return (
    <>
      {/* CHOICES */}
      <QuestionContainer width={width}>
        <Box style={{ width: "100%" }}>
          <Box className="flow-item-wrapper flow-content-spacing">
            <Box className="flow-item-container">
              <div className="TitleQuestion" style={{ padding: "0 40px" }}>
                {getTranslatedText(details[0]?.title)}
                {details[0]?.description && (
                  <div className="TooltipGap">
                    <Tooltip
                      title={getTranslatedText(details[0]?.description)}
                      placement="right"
                    >
                      <InfoOutlinedIcon fontSize="small" />
                    </Tooltip>
                  </div>
                )}
              </div>

              {/* CHOICES */}
              <div
                className="AnswersContainer flow-multiple-choice"
                style={{ padding: isMobile ? "0 40px" : "0 40px 40px 40px" }}
              >
                {details &&
                  details.map((question, idx) => (
                    <div
                      className="button_red_common ButtonMCQAnswer flow-multiple-choice-btn"
                      style={{
                        backgroundColor:
                          clickedItem === idx ? "var(--background)" : "",
                        color: clickedItem === idx ? "var(--text-primary)" : "",
                      }}
                      onClick={() => onSetActive(idx)}
                      key={`choise-${idx}`}
                    >
                      {getTranslatedText(question.choice)}
                    </div>
                  ))}
              </div>
              {showButtons && (
                <div
                  className="NavBtnsContainer"
                  style={{ justifyContent: 'center' }}
                >
                  <FormButtonFM
                    name="Back"
                    route="back"
                  />
                </div>
              )}
            </Box>

            <PetImage />
          </Box>
        </Box>
      </QuestionContainer>
    </>
  );
}

export default MCQ;
