import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { isMobileOnly } from "react-device-detect";
import ModalWrapper from "../../../components/modalWrapper";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  updateIsAdminLoggedAsUser,
  updateIsAdminViewMode,
  updateIsUserModalSelect,
  updateMode,
  updateUser,
} from "../../../config/actions";
import Loading from "../../../components/common/Loading";
import { getToken, setAdminTempToken, setToken } from "../../../config/token";
import { Markup } from "interweave";
import { USER_MODE } from "../../../constants/ApplicationConstants";

function ModalUserSelect() {
  const { t } = useTranslation();
  const router = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [isValidAdminEmail, setIsValidAdminEmail] = useState(false);
  const [codeLoading, setCodeLoading] = useState(false);
  const [problemEmail, setProblemEmail] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [veificationCode, setVeificationCode] = useState("");

  const isModalOpen = useSelector((s) => s.admin.isUserSelectModalOpen);
  const isAdminLoggedAsUser = useSelector((s) => s.admin.isAdminLoggedAsUser);

  const closeModal = () => {
    dispatch(updateIsUserModalSelect(false));
    setEmailSent(false);
    setCodeLoading(false)
    setEmail("")
    setIsValidAdminEmail(false)
    setVeificationCode("")
    setProblemEmail(false);
  };

  const changeEmail = (e) => {
    const inputValue = e.target.value.trim();
    setEmail(inputValue);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(inputValue);
    setIsValidAdminEmail(isValid);
  };

  const EmailConfirm = async () => {
    if (isValidAdminEmail) {
      setCodeLoading(true);

      const token = getToken() ? JSON.parse(getToken()) : undefined;

      fetch("api/users/admin_email_login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.token}`,
        },
        body: JSON.stringify({ email: email }),
      })
        .then((response) => {
          const prevToken = getToken() ? JSON.parse(getToken()) : undefined;
          setAdminTempToken(JSON.stringify({ token: prevToken.token }));
          return response.json(); 
        })

        .then((newTokenResponse) => { 
          setToken(JSON.stringify({ token: newTokenResponse.token }));
          return getUserInfo(newTokenResponse.token);
        })
        .then(() => {
          dispatch(updateIsAdminLoggedAsUser(true));
          dispatch(updateIsUserModalSelect(false));
          dispatch(updateMode(USER_MODE.CLIENT));
        })

        // .then((response) => {
        //   if (response.status === 200) {
        //     setProblemEmail(false);
        //     setEmailSent(true);
        //   } else {
        //     setProblemEmail(true);
        //     setEmailSent(false);
        //   }

        //   return response;
        // })
        // .then((response) => response.json())
        // .then((res) => setVeificationCode(res.message))
        // .finally(() => {
        //   setCodeLoading(false);
        // });
    }
  };

  const changeVerificationCode = (e) => {
    setVeificationCode(e.target.value.trim());
  };

  const codeConfirm = async () => {
    const response = await fetch("api/users/verification", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ code: veificationCode }),
    });

    const prevToken = getToken() ? JSON.parse(getToken()) : undefined;
    setAdminTempToken(JSON.stringify({ token: prevToken.token }));

    const newTokenResponse = await response.json();
    setToken(JSON.stringify({ token: newTokenResponse.token }));
    await getUserInfo(newTokenResponse.token);
    dispatch(updateIsAdminLoggedAsUser(true));
    dispatch(updateIsUserModalSelect(false));
    dispatch(updateMode(USER_MODE.CLIENT));


    closeModal();
    router("/");
    setTimeout(() => {
      window.location.reload();
    }, 100)
  };

  const getUserInfo = async (curToken) => {
    return await fetch("api/users/me", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${curToken}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        dispatch(updateUser(json.data));
      });
  };

  return (
    <ModalWrapper
      headerTitle={t("LoginAsUser")}
      openModal={isModalOpen}
      setModalWrapper={() => closeModal()}
    >
      <div className="modal_container__body__column-modal modal_container__body__column-modal_full-width">
        {!emailSent && (
          <>
            {codeLoading ? (
              <Loading noText fast />
            ) : (
              <div className="modal_container__body__inputs">
                <div
                  className="modal_container__body__text"
                  style={{
                    maxWidth: "540px",
                  }}
                >
                  {t("Email")}
                </div>
                <form
                  className="modal_container__body__form"
                  style={{ width: "100%" }}
                  onSubmit={() => EmailConfirm()}>
                  <input
                    className="modal_container__body__input"
                    style={{
                      maxWidth: "540px",
                      textAlign: "center",
                      padding: "10px 30px",
                    }}
                    placeholder={t("Email")}
                    value={email}
                    onChange={(e) => changeEmail(e)}
                  />
                  <button
                    type="submit"
                    className={`button_red_small ${isMobileOnly && "mobile"}`}
                    disabled={!isValidAdminEmail}
                  >
                    {t("Submit")}
                  </button>
                </form>
              </div>
            )}
          </>
        )}
        {emailSent && (
          <div className="modal_container__body__inputs">
            <div
              className="modal_container__body__text"
              style={{ maxWidth: "540px" }}
            >
              {t("Code")}
            </div>
            <form
              className="modal_container__body__form"
              style={{ width: "100%" }}
              onSubmit={(e) => {
                e.preventDefault();
                codeConfirm();
              }}>
              <input
                className="modal_container__body__input"
                style={{
                  maxWidth: "540px",
                  textAlign: "center",
                  padding: "10px 30px",
                }}
                placeholder={t("Code")}
                value={veificationCode}
                onChange={(e) => changeVerificationCode(e)}
              />
              <button
                type="submit"
                className={`button_red_small ${isMobileOnly && "mobile"}`}
                disabled={!isValidAdminEmail}
              >
                {t("Submit")}
              </button>
            </form>
          </div>
        )}

        {problemEmail && (
          <div
            className="login__body__alert"
            style={{ color: "red", marginTop: 0 }}
          >
            <Markup
              content={t("errorAdminEmail")}
              className="small text-centered"
            />
          </div>
        )}
      </div>
    </ModalWrapper>
  );
}

export default ModalUserSelect;
