import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  Pagination,
  PaginationItem,
  Skeleton,
} from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useTranslation } from "react-i18next";
import FullScreen from "../../../assets/iconsSmall/full-screen.svg";
import Collapse from "../../../assets/iconsSmall/collapse.svg";
import Close from "../../../assets/iconsSmall/close.svg";
import PandaMain from "../../../assets/UserInfo/panda-logo-main.svg";
import ArrowLeft from "../../../assets/PetPage/arrow_left_dashed_small.svg";
import ArrowRight from "../../../assets/PetPage/arrow_right_dashed.svg";
import { ReactComponent as ArrowLeftRed } from "../../../assets/iconsSmall/arrow_left_solid_red.svg";
import { ReactComponent as ArrowRightRed } from "../../../assets/iconsSmall/arrow_right_solid_red.svg";
import { isMobile } from "react-device-detect";
import { useTableScroll } from "../../../hooks/useTableScroll";
import { USER_MODE } from "../../../constants/ApplicationConstants";
import { useSelector } from "react-redux";


function ModalInfo({
  openModalInfo,
  setOpenModalInfo,
  themeSetting,
  isItemsLoading,
  tableInfo,
  petName,
}) {
  const { t } = useTranslation();
  const [isExpand, setIsExpand] = useState(false);
  const mode = useSelector((s) => s.form.mode);

  const style = {
    position: "absolute",
    // top: "50%",
    left: "50%",
    // transform: isMobile ? "translate(-50%, -50%)" : "translate(-50%, -35%)",
    width: isMobile ? "90%" : "80%",
    boxShadow: 12,
    p: 4,
    borderRadius: isMobile ? "20px" : "45px",
    backgroundColor: "var(--b-color-bg-main-1) !important",
    padding: isMobile ? "20px" : "40px",
    transform: "translateX(-50%)",
    margin: "5vh 0 0 0",
    outline: 'none',
  };

  const styleFS = {
    position: isMobile ? "absolute" : "relative",
    top: isMobile ? "50%" : "",
    left: isMobile ? "50%" : "",
    transform: isMobile ? "translate(-50%, -50%)" : "",
    width: "100%",
    boxShadow: 12,
    p: 4,
    borderRadius: isMobile ? "20px" : "45px",
    backgroundColor: "var(--b-color-bg-main-1) !important",
    padding: isMobile ? "20px" : "40px",
    outline: 'none',
  };

  const headers = (mode === USER_MODE.ADMIN) ? ["desc", "got", "quantity", "amount"] : ["desc", "quantity", "amount"];

  function handleClose() {
    setOpenModalInfo(false);
  };

  const [currentClaim, setCurrentClaim] = useState(1);

  const decreasePageNumber = () => {
    if (currentClaim > 1) { setCurrentClaim(currentClaim - 1) };
  };

  const increasePageNumber = () => {
    if (currentClaim < tableInfo?.length) { setCurrentClaim(currentClaim + 1) };
  };

  const handlePage = (event, value) => {
    setCurrentClaim(value);
  };

  const calcTotalAmount = (tableData) => {
    if (!tableData) return null;

    const amount = tableData.reduce((acc, el) => {
      return acc + +el?.payload?.data?.amount;
    }, 0);

    return amount.toFixed(2) + (tableData[0]?.payload?.data?.currency == "$" ? " $" : " €");
  };

  const { bodyRef, headerRef, syncScroll, listOfItemsRef } = useTableScroll();

  return (
    <div className="ContainerVendor" data-theme={themeSetting}>
      <Modal open={openModalInfo} onClose={handleClose} className="ModalCustom">
        <Box sx={isExpand ? styleFS : style} data-theme={themeSetting}>
          <Box sx={{ display: "flex", justifyContent: "flex-end", margin: "0 0 20px 0" }}>
            <div>
              <button
                className="btn-reset"
                onClick={() => setIsExpand(!isExpand)}
              >
                {isExpand
                  ? <img src={Collapse} width={isMobile ? 18 : 34} height={isMobile ? 18 : 34} />
                  : <img src={FullScreen} width={isMobile ? 18 : 34} height={isMobile ? 18 : 34} />}
              </button>
              <button className="btn-reset" onClick={handleClose}>
                <img src={Close} width={isMobile ? 18 : 34} height={isMobile ? 18 : 34} />
              </button>
            </div>
          </Box>

          {isMobile
            ? (
              <div className="SlowShow policy-table-mobile">
                <div className="policy-table-mobile__header">
                  <div
                    className="policy-table-mobile__back"
                    style={currentClaim == 1 ? { opacity: '0.5' } : {}}
                  >
                    <div
                      className="button_red_mobile back margin-0"
                      onClick={decreasePageNumber}
                    >
                      <img src={ArrowLeft} />
                    </div>
                  </div>
                  <div className="policy-table-mobile__img">
                    <img
                      src={PandaMain}
                      alt="Panda_Main_Logo"
                      className="main-logo-policy__mobile"
                    />
                  </div>
                  <div
                    className="policy-table-mobile__next"
                    style={currentClaim >= tableInfo?.length ? { opacity: '0.5' } : {}}
                  >
                    <div
                      className="button_red_mobile back margin-0"
                      onClick={increasePageNumber}
                    >
                      <img src={ArrowRight} />
                    </div>
                  </div>
                </div>
                {!isItemsLoading
                  ? <>
                    {tableInfo.length > 0
                      ? <>
                        <div className="policy-table-mobile__row">
                          <div className="policy-table-mobile__left">{t("desc")}</div>
                          <div className="policy-table-mobile__right">
                            {tableInfo?.[currentClaim - 1]?.payload?.data?.itemName || t("noInfo")}
                          </div>
                        </div>
                        {mode === USER_MODE.ADMIN &&
                          <div className="policy-table-mobile__row">
                            <div className="policy-table-mobile__left">{t("got")}</div>
                            <div className="policy-table-mobile__right">
                              {tableInfo?.[currentClaim - 1]?.payload?.data?.got || t("noInfo")}
                            </div>
                          </div>}
                        <div className="policy-table-mobile__row">
                          <div className="policy-table-mobile__left">{t("quantity")}</div>
                          <div className="policy-table-mobile__right">
                            {tableInfo?.[currentClaim - 1]?.payload?.data?.quantity || t("noInfo")}
                          </div>
                        </div>
                        <div className="policy-table-mobile__row">
                          <div className="policy-table-mobile__left">{t("amount")}</div>
                          <div className="policy-table-mobile__right CellAmountRed">
                            {tableInfo?.[currentClaim - 1]?.payload?.data?.amount
                              ? Number(tableInfo?.[currentClaim - 1]?.payload?.data?.amount).toFixed(2) + (tableInfo?.[currentClaim - 1]?.payload?.data?.currency == "$" ? " $" : " €")
                              : t("noInfo")}
                          </div>
                        </div>
                        <div className="policy-table-mobile__row">
                          <div className="policy-table-mobile__left">{t("totalAmount")}</div>
                          <div className="policy-table-mobile__right total-amount CellAmountRed">
                            {calcTotalAmount(tableInfo) || t("noInfo")}
                          </div>
                        </div>
                        {tableInfo?.length > 1 &&
                          <div className="policy-table-mobile__pagination">
                            <Pagination
                              className="reset-pagination"
                              count={tableInfo?.length}
                              page={currentClaim}
                              siblingCount={1}
                              boundaryCount={1}
                              onChange={handlePage}
                              renderItem={(item) => (
                                <PaginationItem
                                  slots={{ previous: ArrowLeftRed, next: ArrowRightRed }}
                                  {...item}
                                // sx={{
                                //   color: '#FFF',
                                //   backgroundColor: item.type === 'page' && item.page === currentClaim ? '#FE4B4E' : '#DF9F9B',
                                // }}
                                />
                              )}
                            />
                          </div>}
                      </>
                      : <div className="policy-table-mobile__info">{t("noInfo")}</div>}
                  </>
                  : <Skeleton
                    variant="rounded"
                    height={250}
                  />}
              </div>)
            : (
              <div className="table_wrapper">
                <div className="invoice-table-title column-centered" style={{ margin: "0 0 5vh 0" }}>
                  <div className="invoice-table-title__title">{t("invoiceDetails")}</div>
                  <div className="invoice-table-title__name">{petName || t("Name")}</div>
                </div>

                <div className="relative">
                  <img
                    src={PandaMain}
                    alt="Panda_Main_Logo"
                    className="main-logo-modal-table"
                  />
                  {!isItemsLoading
                    ? (
                      <>
                        <TableContainer
                          className="TableClaimsContainerModal reset-table-spacing table-reset-header"
                          sx={{ margin: "0 0 -3px 0" }}
                          ref={headerRef}
                        >
                          <Table className={`SlowShow reset-table-new ${mode === USER_MODE.ADMIN ? "modal-info-admin" : "modal-info"}`}>
                            <TableHead>
                              <TableRow
                                className="reset-table-new__header"
                              >
                                {headers.map((header, index) => (
                                  <TableCell
                                    key={`header-${index}`}
                                    align={index > 0 ? "inherit" : "left"}
                                  >
                                    <div>
                                      {t(header)}
                                    </div>
                                  </TableCell>
                                ))}
                              </TableRow>
                            </TableHead>
                          </Table>
                        </TableContainer>

                        <TableContainer
                          className="TableClaimsContainerModal reset-table-spacing"
                          ref={bodyRef}
                          onScroll={syncScroll}
                        >
                          <Table className={`SlowShow reset-table-new ${mode === USER_MODE.ADMIN ? "modal-info-admin" : "modal-info"}`}>
                            <TableBody ref={listOfItemsRef}>
                              {tableInfo.length > 0
                                ? <>
                                  {tableInfo?.map((row, index) => {
                                    return (
                                      <TableRow
                                        key={`row-${index}`}
                                        className="reset-table-new__body color-modal"
                                      >
                                        <TableCell>
                                          <div className="">{row?.payload?.data?.itemName || t("noInfo")}</div>
                                        </TableCell>
                                        {mode === USER_MODE.ADMIN &&
                                          <TableCell>
                                            <div className="CellDigit">{row?.payload?.data?.got || t("noInfo")}</div>
                                          </TableCell>}
                                        <TableCell>
                                          <div className="CellDigit">{row?.payload?.data?.quantity || t("noInfo")}</div>
                                        </TableCell>
                                        <TableCell>
                                          <div className="CellAmountRed">
                                            {row?.payload?.data?.amount
                                              ? Number(row?.payload?.data?.amount).toFixed(2) + (row?.payload?.data?.currency == "$" ? " $" : " €")
                                              : t("noInfo")}
                                          </div>
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })}
                                  <TableRow
                                    key="total-row"
                                    className="reset-table-new__body total color-modal"
                                  >
                                    <TableCell colSpan={mode === USER_MODE.ADMIN ? 3 : 2}>
                                      <div className="">{t("totalAmount")}</div>
                                    </TableCell>
                                    <TableCell>
                                      <div className="CellAmountRed">
                                        {calcTotalAmount(tableInfo) || t("noInfo")}
                                      </div>
                                    </TableCell>
                                  </TableRow>
                                </>
                                : <TableRow
                                  key={`row-no-data`}
                                  className="reset-table-new__body color-modal"
                                >
                                  <TableCell colSpan={mode === USER_MODE.ADMIN ? 4 : 3}>
                                    <div className="">{t("noInfo")}</div>
                                  </TableCell>
                                </TableRow>
                              }

                            </TableBody>
                          </Table>
                        </TableContainer>
                      </>
                    )
                    : <Skeleton
                      variant="rounded"
                      height={300}
                    />}
                </div>
              </div>)
          }
        </Box>
      </Modal>
    </div>
  );
}

export default ModalInfo;