import React, { useCallback, useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import { Box, Backdrop } from "@mui/material";
import Loading from "../../../components/common/Loading";
import { useTranslation } from "react-i18next";
import { isMobile, isMobileOnly } from "react-device-detect";
import { Request } from "../../../config/requests";
import { getToken } from "../../../config/token";
import CrossBlack from "../../../assets/iconsSmall/cross_black.svg";
import CrossBtn from "../../../assets/iconsSmall/close.svg";

import LeftArrow from "../../../assets/PetPage/arrow_left_dashed_small.svg";
import RightArrow from "../../../assets/PetPage/arrow_right_dashed.svg";
import { Document, Page, pdfjs } from "react-pdf";
import ReactPanZoom from "react-image-pan-zoom-rotate";
import { getAttachedDocs } from "../../../services/clams";
import { getDateGE } from "../../../config/helpers";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function ModalPreview({ isModalOpen, setIsModalOpen, row = null }) {
  const { t } = useTranslation();
  const [currentInd, setCurrentInd] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [contentType, setContentType] = useState(null);
  const [preview, setPreview] = useState(null);
  const [numPages, setNumPages] = useState(null);

  // console.log("ModalPreview > currentInd", currentInd)
  // console.log("ModalPreview > row", row)
  // console.log("ModalPreview > preview", preview)
  // console.log("ModalPreview > contentType", contentType)

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const getAttachedFile = async () => {
    try {
      setIsLoading(true);
      const { data, type } = await getAttachedDocs(row, currentInd);
      setPreview(data);
      setContentType(type);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const showFile = useCallback(() => {
    getAttachedFile();
  }, [currentInd, row?.documents, row.externalClaimId, row.id]);

  useEffect(() => {
    if (row?.documents && row?.documents.length > 0) {
      showFile();
    }
  }, [currentInd, row, showFile]);

  const handleClose = (e) => {
    e?.stopPropagation()
    setIsModalOpen(false);
    setCurrentInd(0);
    setPreview(null);
    setContentType(null);
  };

  return (
    <div className="ContainerVendor">
      <Modal open={isModalOpen} onClose={handleClose} className="ModalVendor">
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            backgroundColor: "transparent",
          }}
          open={true}
        >
          <div className="DescriptionModal MarginBottom">
            {isLoading
              ? <Loading noText fast scroll={false}/>
              : contentType === "application/pdf"
                ? (
                  <div
                    className="relative"
                    style={{ height: "100vh", margin: "0 2vw" }}
                  >
                    <Document
                      file={`data:application/pdf;base64,${preview}`}
                      className="ModalDocumentPDF ModalDocument no-background"
                      onLoadSuccess={onDocumentLoadSuccess}
                    >
                      <div className="ModalHolder relative">
                        <div
                          className="watermark__close watermark__close_transparent pointer"
                          onClick={() => handleClose()}
                        >
                          <img src={CrossBtn} />
                        </div>
                        <div
                          className="headline1_red_title width100"
                          style={{
                            margin: "25px 0 10px 0",
                            textAlign: "center",
                          }}
                        >
                          {t("docPreview")}
                        </div>
                        <div style={{
                          textAlign: "center",
                          margin: "0 0 10px 0",
                          fontSize: "var(--b-font-size-m)",
                        }}>
                          {t("submittedOn")}
                          <strong style={{ fontVariantNumeric: "lining-nums" }}>{getDateGE(row?.documents[currentInd]?.metadata?.uploadedAt)}</strong>
                        </div>
                        <div className="ModalPage" width={isMobileOnly ? 300 : ""} >
                          {Array.from(new Array(numPages), (el, index) => (
                            <Page key={index + 1} pageNumber={index + 1} renderTextLayer={false} renderAnnotationLayer={false} />
                          ))}
                        </div>

                        {row?.documents &&
                          row?.documents.length > 1 &&
                          <div
                            className="preview-btns"
                          >
                            <button
                              className="button_red_mobile back margin-0 no-border"
                              onClick={(e) => {
                                e.stopPropagation()
                                setCurrentInd(currentInd - 1)
                              }}
                              disabled={currentInd === 0}
                              style={{ opacity: currentInd === 0 ? 0.5 : 1 }}
                            >
                              <img src={LeftArrow} />
                            </button>
                            <button
                              className="button_red_mobile back margin-0 no-border"
                              onClick={(e) => {
                                e.stopPropagation()
                                setCurrentInd(currentInd + 1)
                              }}
                              disabled={currentInd === row?.documents.length - 1}
                              style={{ opacity: currentInd === row?.documents.length - 1 ? 0.5 : 1 }}
                            >
                              <img src={RightArrow} />
                            </button>
                          </div>}
                      </div>
                    </Document>
                  </div>
                ) : (
                  <div
                    className="relative preview-file"
                    style={{ height: isMobile ? "auto" : "unset", margin: "0 2vw" }}
                  >
                    <div
                      className="headline1_red_title width100"
                      style={{
                        margin: "25px 0 10px 0",
                        textAlign: "center",
                      }}
                    >
                      {t("docPreview")}
                    </div>
                    <div style={{
                      textAlign: "center",
                      margin: "0 0 10px 0",
                      fontSize: "var(--b-font-size-m)",
                    }}>
                      {t("submittedOn")}
                      <strong style={{ fontVariantNumeric: "lining-nums" }}>{getDateGE(row?.documents[currentInd]?.metadata?.uploadedAt)}</strong>
                    </div>
                    <div
                      className="watermark__close watermark__close_transparent pointer"
                      onClick={() => handleClose()}
                    >
                      <img src={CrossBtn} />
                    </div>

                    <div className="flow-preview-file">
                      <ReactPanZoom
                        image={`data:image/jpeg;base64, ${preview}`}
                        alt="Image alt text"
                      />
                    </div>

                    {row?.documents &&
                      row?.documents.length > 1 &&
                      <div
                        className="preview-btns"
                      >
                        <button
                          className="button_red_mobile back margin-0 no-border"
                          onClick={(e) => {
                            e.stopPropagation()
                            setCurrentInd(currentInd - 1)
                          }}
                          disabled={currentInd === 0}
                          style={{ opacity: currentInd === 0 ? 0.5 : 1 }}
                        >
                          <img src={LeftArrow} />
                        </button>
                        <button
                          className="button_red_mobile back margin-0 no-border"
                          onClick={(e) => {
                            e.stopPropagation()
                            setCurrentInd(currentInd + 1)
                          }}
                          disabled={currentInd === row?.documents.length - 1}
                          style={{ opacity: currentInd === row?.documents.length - 1 ? 0.5 : 1 }}
                        >
                          <img src={RightArrow} />
                        </button>
                      </div>}
                  </div>
                )}
          </div>
        </Backdrop>
      </Modal>
    </div>
  );
}

export default ModalPreview;
