import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import Loading from "../../../../components/common/Loading";
import { useTranslation } from "react-i18next";
import { Box, Backdrop, Modal } from "@mui/material";
import { isMobile, isMobileOnly } from "react-device-detect";
import CrossBtn from "../../../../assets/iconsSmall/close.svg";
import ReactPanZoom from "react-image-pan-zoom-rotate";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PreviewFileModal = ({
  preview,
  selectedFile,
  fileType,
  setSelectedFile,
}) => {
  const { t } = useTranslation();
  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const closePDF = (e) => {
    e.preventDefault();
    setSelectedFile(null);
  };

  return (
    <>
      {selectedFile && fileType === "application/pdf" && (
        <Modal open={true} onClose={(e) => closePDF(e)} className="ModalVendor">
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 1,
              backgroundColor: "transparent",
            }}
            open={true}
          >
            {!preview ? (
              <Loading noText fast />
            ) : (
              <Document file={preview} className="ModalDocument no-background" onLoadSuccess={onDocumentLoadSuccess}>
                <div className="ModalHolder relative">
                  <div
                    className="watermark__close watermark__close_transparent pointer positioned-label"
                    onClick={(e) => closePDF(e)}
                  >
                    <img src={CrossBtn} />
                  </div>

                  <div
                    className="headline1_red_title width100"
                    style={{
                      margin: "25px 0 10px 0",
                      textAlign: "center",
                    }}
                  >
                    {t("docPreview")}
                  </div>
                  <div className="ModalPage" width={isMobileOnly ? 300 : ""} >
                    {Array.from(new Array(numPages), (el, index) => (
                      <Page key={index + 1} pageNumber={index + 1} renderTextLayer={false} renderAnnotationLayer={false} />
                    ))}
                  </div>
                </div>
              </Document>
            )}
          </Backdrop>
        </Modal>
      )}

      {selectedFile && fileType !== "application/pdf" && (
        <Modal open={true} onClose={(e) => closePDF(e)} className="ModalVendor">
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 1,
              backgroundColor: "transparent",
            }}
            open={true}
          >
            {!preview ? (
              <Loading noText fast />
            ) : (
              <div
                className="relative preview-file"
                style={{ height: isMobile ? "auto" : "unset", margin: "0 2vw" }}
              >
                <div
                  className="headline1_red_title width100"
                  style={{
                    margin: "25px 0 10px 0",
                    textAlign: "center",
                  }}
                >
                  {t("docPreview")}
                </div>
                <div
                  className="watermark__close watermark__close_transparent pointer"
                  onClick={(e) => closePDF(e)}
                >
                  <img src={CrossBtn} />
                </div>

                <div className="flow-preview-file">
                  <ReactPanZoom image={preview} alt="Image alt text" />
                </div>
              </div>
            )}
          </Backdrop>
        </Modal>
      )}
    </>
  );
};

export default PreviewFileModal;
