import { Box, TextField, Tooltip } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import React, { memo, useEffect, useState } from "react";
import FormButtonFM from "./FormButtonFM";
import QuestionContainer from "./QuestionContainer";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import useTranslations from "../../../../hooks/useTranslations";
import PetImage from "../../../common/components/PetImage";
import useQuestionContainer from "../../../../hooks/useQuestionContainer";
import 'moment/locale/de';
import 'moment/locale/en-gb';
import i18n from "../../../../lang/i18n";

function Date({
  data,
  setData,
  details,
  showButtons = true,
  onUpdate,
  width,
  single = false,
}) {
  const [date, setDate] = useState(
    details.answer ? moment(details.answer, "DD-MM-YYYY") : null
  );
  const { t } = useTranslation();
  const defaultValues = useSelector((s) => s.flow.uploadValues);
  const { getTranslatedText } = useTranslations();

  const [questionContainer, buttonContainer] = useQuestionContainer();

  const currentLanguage = i18n.language;

  if (currentLanguage === 'de') {
    moment.locale('de');
  } else if (currentLanguage === 'en') {
    moment.locale('en-gb');
  }

  useEffect(() => {
    const values = defaultValues[details.uploadId]?.details;
    if (details.isOcr && values && !details.answer) {
      const value = values[details.defaultValue]?.content;
      setDate(value ? moment(value, "DD.MM.YYYY") : null);
      details["answer"] = value || "";
    }
  }, [defaultValues, details]);

  useEffect(() => {
    const ind = data.manual.findIndex((i) => i.name === details.title);
    const d = data.manual[ind];
    data.manual.splice(ind, 1);
    setData({
      ...data,
      manual: [...data.manual, { ...d, value: date }],
    });
  }, [date]);

  const handleDate = (newValue) => {
    setDate(newValue);

    details["answer"] =
      newValue?.format("DD-MM-YYYY") === "Invalid date"
        ? null
        : newValue?.format("DD-MM-YYYY");
    let item = data.manual?.find((i) => i.name === details.title);
    if (item) {
      setData({
        ...data,
        manual: data.manual.map((i) =>
          i.name === details.title ? { ...i, value: details.answer } : i
        ),
      });
    } else {
      item = {
        name: details.title,
        value: details.answer,
      };
      setData({
        ...data,
        manual: [...data.manual, item],
      });
    }
  };

  return (
    <QuestionContainer width={width}>
      <Box style={{ width: "100%" }}>
        <Box className="flow-item-wrapper flow-content-spacing ">
          <Box className="flow-item-container">
            <div
              className="TitleQuestion"
              style={{ padding: single ? "0 20px" : "" }}
            >
              {getTranslatedText(details.title)}
              {details.description && (
                <div className="TooltipGap">
                  <Tooltip
                    title={getTranslatedText(details.description)}
                    placement="right"
                  >
                    <InfoOutlinedIcon fontSize="small" />
                  </Tooltip>
                </div>
              )}
            </div>

            <div
              className="AnswersContainer"
              style={{ padding: single ? "0 20px" : "" }}
            >
              <DatePicker
                className="flow-date-picker"
                placeholder={t("date")}
                slotProps={{ textField: { placeholder: t("date") } }}
                value={date}
                onChange={(newValue) => {
                  handleDate(newValue);
                }}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </div>
            {showButtons && (
              <div
                className="NavBtnsContainer"
                style={{ padding: single ? "0 20px" : "" }}
              >
                <FormButtonFM
                  name="Back"
                  route="back"
                />
                <FormButtonFM
                  name="Next"
                  route="next"
                  onUpdate={onUpdate}
                  data={data}
                  disabled={!date || date === null}
                />
              </div>
            )}
          </Box>
          {single && <PetImage />}
        </Box>
      </Box>
    </QuestionContainer>
  );
}

export default memo(Date);
